/* eslint-disable @typescript-eslint/naming-convention */
import { feedbacksTabKey } from '../logs/logs.page';

export const storageKeys = {
  checklists: 'checklists',
  materials: 'materials',
  options: 'options',
  role: 'role',
  user: 'user',
  newAdhoc: 'newAdhoc',
};

export const maxListItems = 5;
export const firestoreInLimit = 30;

export const allowedDistance = 1;
export const notificationTimeMS = 1000 * 60 * 10;
export const lunchBreakMS = 1000 * 60 * 30;

export const userDocuments = ['workSecurity', 'workingPermit', 'passport', 'idCard'];

export const notificationKeys = {
  assign: 'assign',
  request: 'request',
  feedbacks: feedbacksTabKey,
  orders: 'orders',
  ticket: 'ticket',
  reservation: 'reservation',
};

export const moreFloors = ['0', '-1', '-2', '-3', '-4', '-5', 'P'];

export enum Languages {
  'fi' = 'fi',
  'en' = 'en',
  'uk' = 'uk',
  'ar' = 'ar',
  'de' = 'de',
  'sv' = 'sv',
  'ee' = 'ee',
  'ru' = 'ru',
  'fr' = 'fr',
  'sq' = 'sq',
  'ro' = 'ro',
  'ja' = 'ja',
  'zh' = 'zh',
  'ko' = 'ko',
  'fil' = 'fil',
}

export const LANGUAGES = ['fi', 'en', 'ar', 'uk', 'ee', 'de', 'fr', 'sv', 'ru', 'sq', 'ro', 'ja', 'zh', 'ko', 'fil'];

export const partnerPercentage = 0.2;
export const partnerExpiresMonths = 24;

export const maxAIPrompts = 5;
export const maxTasks = 20;
export const stripeTrialDays = 30;

export const tes = ['construction', 'realEstate', 'noSelection'];
export const workTypes = ['40h', 'noSelection'];
export const paymentTerms = ['7days', '14days', '21days', '30days', 'noSelection'];

export const mobileWidth = 767;
