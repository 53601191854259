<app-content class="link"
  (click)="onItem(item())">
  @if ({
    preview: item().preview$ | async
  }; as vm) {
    <div class="image-container"
      [class.empty]="vm.preview == null && item().image == null">
      @if (hasVideo()) {
        <div class="video-thumb">
          <ion-icon name="play-outline"></ion-icon>
        </div>
      }
      @if (vm.preview) {
        @if (!previewLoaded()) {
          <ion-spinner></ion-spinner>
        }
        <img [hidden]="!previewLoaded()"
          [src]="vm.preview"
          (loadstart)="onPreview(vm.preview)"
          (load)="onPreviewLoaded(vm.preview)" />
      } @else if (item().image) {
        @if (item().preview$ == null) {
          <img [src]="item().image" />
        }
      } @else if (item().video) {
        <div class="video-thumb">
          <ion-icon name="play-outline"></ion-icon>
        </div>
        <video preload="metadata">
          <source [src]="item().video + '#t=0.5'"
            type="video/mp4">
        </video>
      } @else {
        <ion-icon name="image-outline"></ion-icon>
      }
      @if (item().logo) {
        <div class="logo-container"><img [src]="item().logo" /></div>
      }
      @if (item().valid) {
        <ion-icon class="valid"
          color="success"
          name="checkmark-circle-outline"></ion-icon>
      } @else if (item().valid === false) {
        <ion-icon class="valid"
          color="danger"
          name="close-circle-outline"></ion-icon>
      }
      @if (item().statusColor) {
        <ion-icon class="status"
          [color]="item().statusColor"
          name="ellipse"></ion-icon>
      }
    </div>
  }
  <div class="info">
    @if (item().site) {
      <ion-note>{{ item().site }}</ion-note>
      <br />
    }
    <ion-note>{{ item().info }}</ion-note>
  </div>
  @if (item().created) {
    <div class="flex flex-justify-between dates">
      <ion-note>
        <ion-icon name="calendar-clear-outline"></ion-icon>
        {{ item().created | timestamp:'d.M.yyyy' }}
      </ion-note>
      <ion-note>
        <ion-icon name="calendar-clear-outline"></ion-icon>
        {{ item().date | timestamp:'d.M.yyyy' }}
      </ion-note>
    </div>
  }
  <div class="name">
    {{ item().name | translateTicket | async }}
    @if (item().name3) {
      <div>
        {{ item().name3 }}
      </div>
    }
    @if (item().name2) {
      <div class="ion-margin-top text-container"
        [innerHTML]="item().name2"></div>
    }
  </div>
  @if (item().text) {
    <div class="ellipsis text-container">{{ item().text }}</div>
  }
  <div class="flex">
    @if (item().date && !item().created) {
      <span>
        <ion-icon name="calendar-clear-outline"></ion-icon>
        {{ item().date | timestamp:'d.M.yyyy' }}
      </span>
    }
    @if (item().hours) {
      <span>
        <ion-icon name="time"></ion-icon>
        {{ item().hours }}h
      </span>
    }
  </div>
  @if (item().actionName) {
    <div>
      <ion-button size="small"
        shape="round"
        (click)="item().action()">
        {{ item().actionName }}
      </ion-button>
    </div>
  }
</app-content>