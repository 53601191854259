import { Room } from '../models/room.model';
import { RoomTicket, TicketStatus } from '../models/ticket.model';

export const defaultTask = 'Ei tehtävävalintaa';
export const defaultTaskOnSave = 'Tehtävä';
export const ticketCreated = 'messages.ticket.created';

export const maxImages = 20;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRoomTicket = (object: any): object is RoomTicket => {
  if (object == null) {
    return false;
  }
  return 'room' in object && 'status' in object;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRoom = (object: any): object is Room => {
  if (object == null) {
    return false;
  }
  return 'id' in object;
};

export function getStatusColor(roomTicket: RoomTicket) {
  switch (roomTicket.status) {
    case TicketStatus.inProgress:
      return 'warning';
    case TicketStatus.paused:
      return 'warning';
    case TicketStatus.done:
      return 'success';
    case TicketStatus.checked:
      return 'success';
    default:
      return 'danger';
  }
}
