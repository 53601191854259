import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { documentId, where } from '@angular/fire/firestore';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IService } from '../models/service.interface';
import { RoomTicket, Ticket } from '../models/ticket.model';
import { firestoreInLimit } from '../utility/constants';
import { FirestoreService } from './firestore.service';

export function cleanRoomTicket(rt: RoomTicket) {
  delete rt.date;
  delete rt.preview$;
  delete rt.room;
  delete rt.site;
  delete rt.parent;
  delete rt.start;
  delete rt.end;
  delete rt.progress;
  delete rt.checkedInfo;
  delete rt.userImage;
  delete rt.daysOpen;
  delete rt.contractorName;
  delete rt.usedTimeInH;
  return rt;
}

export function cleanTicket(ticket: Ticket) {
  ticket.tickets = ticket?.tickets?.map((rt) => {
    return cleanRoomTicket(rt);
  });
  delete ticket?.room;
  delete ticket?.siteObj;
  delete ticket?.hours$;
  delete ticket?.roomSummary;
  delete ticket?.roomSummaryInDays;
  return ticket;
}

const converter = {
  toFirestore: (item: Ticket) => {
    return cleanTicket(item);
  },
  fromFirestore: (snapshot, options) => {
    let data = snapshot.data(options) as Ticket;
    data.tickets.map((rt) => {
      rt.contractor = data.contractor ?? null;
      rt.parent = Object.assign({}, data);
      return rt;
    });

    return data;
  },
};

const itemCollection = 'tickets';
const url = `${environment.backendUrl}/tickets`;

@Injectable({
  providedIn: 'root',
})
export class TicketsService implements IService<Ticket> {
  constructor(
    private firestore: FirestoreService,
    private http: HttpClient,
  ) { }

  private setDates(t: RoomTicket): RoomTicket {
    if (t) {
      t.history = t.history.map((it) => {
        if (it.timestamp.toDate != null) {
          it.timestamp = it.timestamp.toDate() ?? undefined;
        }
        return it;
      });

      t.notes = t.notes?.map((it) => {
        if (it.timestamp.toDate != null) {
          it.timestamp = it.timestamp.toDate() ?? undefined;
        }
        return it;
      }) ?? [];

      if (t.additionalWorkInfo) {
        if (t.additionalWorkInfo.adminTimestamp?.toDate) {
          t.additionalWorkInfo.adminTimestamp = t.additionalWorkInfo.adminTimestamp.toDate();
        }
        if (t.additionalWorkInfo.clientTimestamp?.toDate) {
          t.additionalWorkInfo.clientTimestamp = t.additionalWorkInfo.clientTimestamp.toDate();
        }
      }

      if (t.deadline) {
        t.deadline = t.deadline.toDate != null ? t.deadline.toDate() : t.deadline;
      }
      if (t.schedule) {
        t.schedule.start = t.schedule.start.toDate != null ? t.schedule.start.toDate() : t.schedule.start;
        t.schedule.end = t.schedule.end.toDate != null ? t.schedule.end.toDate() : t.schedule.end;
      }
    }
    return t;
  }

  async save(item: Ticket) {
    return this.firestore.saveAs<Ticket>(itemCollection, item.guid, item, converter);
  }

  async update(item: Ticket) {
    const ticket = Object.assign({}, cleanTicket(item));
    return this.firestore.update(itemCollection, ticket);
  }

  async updateOnly(item: Ticket) {
    const ticket = Object.assign({}, cleanTicket(item));
    return this.firestore.updateOnly(itemCollection, item.guid, ticket);
  }

  get(guid: string): Observable<Ticket> {
    return this.firestore.get<Ticket>(itemCollection, guid, converter).pipe(
      map((res) => {
        res?.tickets?.map((ticket) => {
          this.setDates(ticket);
          return ticket;
        });
        return res;
      }),
    );
  }

  getOnce(guid: string): Observable<Ticket> {
    return this.firestore.getOnce<Ticket>(itemCollection, guid, converter).pipe(
      map((res) => {
        res?.tickets?.map((ticket) => {
          this.setDates(ticket);
          return ticket;
        });
        return res;
      }),
    );
  }

  getList(options?: {
    guids?: string[];
    area?: string;
    site?: string;
    sites?: string[];
    contractors?: string[];
    contractor?: string;
    roomId?: string;
  }): Observable<Ticket[]> {
    if (options == null) {
      return of([]);
    }

    if (options.guids) {
      let ids = [...options.guids];
      if (ids?.length === 0) {
        return of([]);
      }
      const batches: Observable<Ticket[]>[] = [];

      while (ids.length) {
        const batch = ids.splice(0, firestoreInLimit);
        batches.push(this.firestore
          .getList<Ticket>(itemCollection, undefined, [where(documentId(), 'in', [...batch])], converter,
          ));
      }

      return combineLatest(batches).pipe(
        map((results) => results.flat()),
        map((results) => results.map((ticket) => {
          ticket.tickets.map((roomTicket) => {
            this.setDates(roomTicket);
            return roomTicket;
          });
          return ticket;
        })),
      );
    } else {
      const queryConstraints = [];
      if (options.area) {
        queryConstraints.push(where('area', '==', options.area));
      }
      if (options.site) {
        queryConstraints.push(where('site', '==', options.site));
      }
      if (options.contractor || options.contractor === null) {
        queryConstraints.push(where('contractor', '==', options.contractor));
      }
      if (options.contractors) {
        queryConstraints.push(where('contractor', 'in', options.contractors));
      }
      if (options.sites) {
        queryConstraints.push(where('site', 'in', options.sites));
      }
      if (options.roomId) {
        queryConstraints.push(where('roomId', '==', options.roomId));
      }
      return this.firestore
        .getList<Ticket>(itemCollection, undefined, queryConstraints, converter)
        .pipe(
          map((results) => results.map((ticket) => {
            ticket.tickets.map((roomTicket) => {
              this.setDates(roomTicket);
              return roomTicket;
            });
            return ticket;
          })),
        );
    }
  }

  getListOnce(options?: {
    guids?: string[];
    area?: string;
    site?: string;
    sites?: string[];
    contractors?: string[];
    contractor?: string;
    roomId?: string;
  }): Observable<Ticket[]> {
    if (options == null) {
      return of([]);
    }

    if (options.guids) {
      let ids = [...options.guids];
      if (ids?.length === 0) {
        return of([]);
      }
      const batches: Observable<Ticket[]>[] = [];

      while (ids.length) {
        const batch = ids.splice(0, firestoreInLimit);
        batches.push(this.firestore
          .getListOnce<Ticket>(itemCollection, undefined, [where(documentId(), 'in', [...batch])], converter,
          ));
      }

      return combineLatest(batches).pipe(
        map((results) => results.flat()),
        map((results) => results.map((ticket) => {
          ticket.tickets.map((roomTicket) => {
            this.setDates(roomTicket);
            return roomTicket;
          });
          return ticket;
        })),
      );
    } else {
      const queryConstraints = [];
      if (options.area) {
        queryConstraints.push(where('area', '==', options.area));
      }
      if (options.site) {
        queryConstraints.push(where('site', '==', options.site));
      }
      if (options.contractor || options.contractor === null) {
        queryConstraints.push(where('contractor', '==', options.contractor));
      }
      if (options.contractors) {
        queryConstraints.push(where('contractor', 'in', options.contractors));
      }
      if (options.sites) {
        queryConstraints.push(where('site', 'in', options.sites));
      }
      if (options.roomId) {
        queryConstraints.push(where('roomId', '==', options.roomId));
      }
      return this.firestore
        .getListOnce<Ticket>(itemCollection, undefined, queryConstraints, converter)
        .pipe(
          map((results) => results.map((ticket) => {
            ticket.tickets.map((roomTicket) => {
              this.setDates(roomTicket);
              return roomTicket;
            });
            return ticket;
          })),
        );
    }
  }

  translateField(ticketGuid: string, index: number, field: string, history = false, note = false, roomTicketGuid: string = null) {
    const body = {
      guid: ticketGuid,
      index,
      field,
      history,
      note,
      roomTicketGuid,
    };
    return this.http.post(url, body);
  }
}
