import { CommonModule } from '@angular/common';
import { Component, OnDestroy, signal } from '@angular/core';
import { IonSearchbar, IonicModule, ModalController, NavParams } from '@ionic/angular';
import { LogoModule, fieldSorter } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { FreeLicenseDialogComponent } from 'src/app/contractors/free-license/free-license.dialog';
import { Contractor } from 'src/app/models/contractor.model';
import { Site } from 'src/app/models/site.model';
import { ContractorsService } from 'src/app/services/contractors.service';
import { LicenseService } from 'src/app/services/licence.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UsersService } from 'src/app/services/users.service';
import { NewSiteDialogComponent } from 'src/app/sites/new-site/new-site.dialog';
import { Roles } from 'src/app/utility/role';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-select-site-dialog',
  templateUrl: './select-site.dialog.html',
  styleUrls: ['./select-site.dialog.scss'],
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
    LogoModule,
  ],
})
export class SelectSiteDialog implements OnDestroy {
  private destroy = new ReplaySubject<void>(1);
  private clientGuid: string;
  private isShowDisabled$ = new BehaviorSubject<boolean>(false);
  filter$ = new BehaviorSubject<string>('');

  sites$ = new BehaviorSubject<Site[]>([]);

  filteredSites$ = combineLatest([
    this.filter$.asObservable(), this.sites$.asObservable(), this.isShowDisabled$.asObservable(), this.contractorsService.getCurrentContractor(),
  ]).pipe(
    map(([filterValue, items, isShowDisabled, contractor]) => items.filter((site) =>
      (site.guid.toLowerCase().includes(filterValue.toLowerCase())
        || site.name.toLowerCase().includes(filterValue.toLowerCase()))
      && (isShowDisabled ? this.isDisabled(site, contractor) : !this.isDisabled(site, contractor)))),
    shareReplay(1),
  );

  isNewSite = signal(false);
  isDisabledToggleVisible = signal(false);
  selected = signal<Site>(null);

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  constructor(
    private contractorsService: ContractorsService,
    private licenseService: LicenseService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private timesheetsService: TimesheetsService,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.isNewSite.set(navParams.get('showAdd') ?? false);
    this.clientGuid = navParams.get('clientGuid');
    const isDisabledToggleParam = navParams.get('isDisabledToggle') ?? false;
    this.isDisabledToggleVisible.set(isDisabledToggleParam);
    const paramSites: Site[] = navParams.get('sites');
    const role = this.usersService.currentUserS()?.role;
    if (role === Roles.worker) {
      this.timesheetsService.getListOnce({
        user: this.usersService.currentUserS().guid,
        startDate: new Date(),
        endDate: new Date(),
      }).pipe(
        take(1),
      ).subscribe((timesheets) => {
        if (timesheets?.length > 0) {
          const siteGuids = timesheets.map((it) => it.site);
          const loggedInSites = paramSites.filter((it) => siteGuids.includes(it.guid));
          this.sites$.next([...loggedInSites, ...paramSites.filter((it) => !siteGuids.includes(it.guid))]);
        } else {
          this.sites$.next(paramSites.sort(fieldSorter(['name'])));
        }
      });
    } else {
      if (this.isNewSite()) {
        const contractor = this.contractorsService.contractorS();
        this.sitesService.getAdminListFromDb(null).pipe(
          map((sites) => sites
            .filter((site) => !contractor?.sites.includes(site.guid))
            .filter((site) => !this.clientGuid || site.client === this.clientGuid)
            .filter((site) => site.contractor == null || contractor?.guid === site.contractor)),
          debounceTime(500),
          takeUntil(this.destroy),
        ).subscribe((sites) => {
          const newSites = sites.sort(fieldSorter(['name']));
          this.sites$.next(newSites);
        });
      } else {
        this.sites$.next(paramSites.sort(fieldSorter(['name'])));
      }
    }
  }

  private isDisabled(site: Site, contractor: Contractor) {
    if (site.archived) {
      return true;
    }
    return contractor?.archivedSites?.includes(site.guid) ?? false;
  }

  toggleDisabled() {
    const currentValue = this.isShowDisabled$.getValue();
    this.isShowDisabled$.next(!currentValue);
  }

  async addNew(isFree: boolean) {
    if (isFree) {
      return this.modalCtrl.create({ component: FreeLicenseDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
    }
    this.modalCtrl.create({ component: NewSiteDialogComponent, componentProps: { clientGuid: this.clientGuid } }).then((m) => {
      m.present();
      m.onDidDismiss().then((data) => {
        if (data.data) {
          const siteImageOptions = data.data.siteImageOptions;
          const site = data.data;
          delete site.file;
          delete site.siteImageOptions;
          this.sitesService.save(site).then((docRef) => {
            site.guid = docRef.id;
            const user = this.usersService.currentUserS();
            if ([Roles.admin].includes(user.role)) {
              const contractor = this.contractorsService.contractorS();
              if (contractor.siteOptions == null) {
                contractor.siteOptions = [];
              }
              const existingOptions = contractor.siteOptions.find((it) => it.site === site.guid);
              if (existingOptions) {
                existingOptions.images = siteImageOptions;
              } else {
                contractor.siteOptions.push({ site: site.guid, images: siteImageOptions });
              }
              this.contractorsService.update(contractor).then(() => this.dismiss(site));
            } else {
              this.dismiss(site);
            }
          });
        }
      });
    });
  }

  onFilter(input: IonSearchbar) {
    const filterValue = input.value;
    this.filter$.next(filterValue);
    this.selected.set(null);
  }

  select(site: Site) {
    if (this.isNewSite()) {
      if (this.selected()?.guid === site.guid) {
        this.selected.set(null);
      } else {
        this.selected.set(site);
      }
    } else {
      this.dismiss(site);
    }
  }

  save() {
    this.dismiss(this.selected());
  }

  dismiss(item?: Site) {
    this._modal.dismiss(item);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
