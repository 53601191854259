<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (site) {
      {{ 'tickets.freemium.assignTicket' | translate }}
    } @else {
      {{ 'users.invite' | translate }}
    }
  </div>
  @if ({
    subscriptions: subscriptions$ | async
  }; as vm) {
    @switch (step()) {
      @case (1) {
        @if (site) {
          @if (vm.subscriptions?.length > 0) {
            <h4>
              {{ 'tickets.freemium.selectWorker' | translate }}
            </h4>
          }
          @for(sub of vm.subscriptions; track sub.guid) {
            <ion-item button="true"
              lines="none"
              (click)="selectWorker(sub.metadata)">
              {{ sub.metadata?.name ?? sub.metadata?.email }}
            </ion-item>
          } @empty {
            <div class="card">
              {{ 'tickets.freemium.openAccount' | translate }}
            </div>
          }
        }
        <h4>{{ 'tickets.freemium.addNewWorker' | translate }}</h4>
        <div class="ion-margin-top">
          <form [formGroup]="inviteFormGroup">
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                formControlName="name"
                [placeholder]="'tickets.freemium.workerName' | translate"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-input formControlName="email"
                type="email"
                [placeholder]="'tickets.freemium.workerEmail' | translate"></ion-input>
            </ion-item>
            @if (error()) {
              <div class="ion-margin-top">
                <ion-label color="danger">{{ 'errors.' + error() | translate }}</ion-label>
              </div>
            }
            <div class="ion-margin-top">
              <ion-button color="light"
                [disabled]="inviteFormGroup?.invalid || loading()"
                shape="round"
                (click)="invite(vm.subscriptions)">
                @if (loading()) {
                  <ion-spinner></ion-spinner>
                }
                {{ 'tickets.freemium.next' | translate }}
              </ion-button>
            </div>
          </form>
        </div>
      } @case (2) {
        <h3>{{ 'free.terms' | translate }}</h3>
        <div>
          <ion-button color="light"
            shape="round"
            (click)="showTerms()">
            {{ 'tickets.freemium.showTerms' | translate }}
          </ion-button>
        </div>
        <div class="terms"
          [innerHTML]="'tickets.freemium.terms' | translate"></div>
        <div class="ion-margin-top">{{ 'free.trial' | translate }}</div>
        <div class="ion-margin-top">
          <ion-button color="light"
            [disabled]="loading()"
            shape="round"
            (click)="accept()">
            @if (loading()) {
              <ion-spinner></ion-spinner>
            }
            {{ 'tickets.freemium.acceptTerms' | translate }}
          </ion-button>
        </div>
      } @case (3) {
        <div class="ion-margin-bottom">
          {{ 'tickets.freemium.summary' | translate:{ email: worker().email } }}
        </div>
        <ion-button color="light"
          [disabled]="inviteFormGroup?.invalid || loading()"
          shape="round"
          (click)="subscribe(vm.subscriptions)">
          @if (loading()) {
            <ion-spinner></ion-spinner>
          }
          {{ 'tickets.freemium.subscribe' | translate }}
        </ion-button>
      }
    }
  }
</app-main-dialog>