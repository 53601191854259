@if ({
scroll: scrollTo$ | async
}; as vm) {}
@if (offline$ | async) {
  <div class="offline-container">
    {{ 'general.offline' | translate }}
  </div>
}
<ion-content color="primary">
  <ng-scrollbar #scrollbar
    orientation="vertical"
    visibility="hover">
    <div class="space"></div>
    @if (!hideHeader()) {
      <app-header>
        <ng-content select="[desktopTitle]"></ng-content>
      </app-header>
    }
    <div class="segment-container">
      <ng-content select="[segmentContainer]"></ng-content>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </ng-scrollbar>
</ion-content>