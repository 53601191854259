<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'clients.new' | translate }}
  </div>
  @if ({
  clients: filteredClients$ | async,
  filter: filter$ | async
  }; as vm) {
    <p>{{ 'clients.info' | translate }}</p>
    <p>{{ 'clients.info2' | translate }}</p>
    <ion-searchbar mode="ios"
      [placeholder]="'general.search' | translate"
      type="text"
      debounce="500"
      (ionInput)="onChange($event)"></ion-searchbar>
    @if (showAllClients()) {
      <div>{{ 'clients.existing' | translate }}</div>
      @for (item of currentClients$ | async; track item.guid) {
        <ion-item button="true"
          (click)="save(item)">
          @if (selected()?.guid === item.guid) {
            <ion-icon slot="start"
              name="checkmark-outline"></ion-icon>
          } @else {
            @if (item.logo) {
              <app-logo slot="start"
                [logo]="item.logo"></app-logo>
            } @else {
              <div slot="start"
                class="logo">
                <img src="./assets/images/logo.png" />
              </div>
            }
          }
          <span class="ellipsis">
            {{ item.name }}
          </span>
        </ion-item>
      }
    }
    @if (vm.filter?.length > 0) {
      @for (item of vm.clients; track item.guid) {
        <ion-item button="true"
          (click)="select(item)">
          @if (selected()?.guid === item.guid) {
            <ion-icon slot="start"
              name="checkmark-outline"></ion-icon>
          } @else {
            @if (item.logo) {
              <app-logo slot="start"
                [logo]="item.logo"></app-logo>
            } @else {
              <div slot="start"
                class="logo">
                <img src="./assets/images/logo.png" />
              </div>
            }
          }
          <span class="ellipsis">
            {{ item.name }}
          </span>
        </ion-item>
      }
    }
    @if (vm.filter?.length > 0 && vm.clients.length === 0) {
      <ion-button color="secondary"
        shape="round"
        (click)="addNew()">
        <ion-icon slot="start"
          name="add-outline"></ion-icon>
        {{ 'users.addClient' | translate }}
      </ion-button>
    }
  }
</app-main-dialog>
@if (selected()) {
  <ion-fab slot="fixed"
    horizontal="end"
    vertical="bottom">
    <div class="card">
      {{ 'clients.selecting' | translate }}
      <ion-button shape="round"
        (click)="save()">
        {{ 'clients.add' | translate }}
      </ion-button>
    </div>
  </ion-fab>
}