export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAeP2sdhoQUDcc3HO6uMvuKbC-wQ-TniG4",
    authDomain: "isoharja-demo.firebaseapp.com",
    projectId: "isoharja-demo",
    storageBucket: "isoharja-demo.appspot.com",
    messagingSenderId: "472718296851",
    appId: "1:472718296851:web:0dd35e18b7eac5ece5bce6"
  },
  googleMapsKey: {
    web: 'AIzaSyAeP2sdhoQUDcc3HO6uMvuKbC-wQ-TniG4',
    ios: 'AIzaSyAeP2sdhoQUDcc3HO6uMvuKbC-wQ-TniG4',
    android: 'AIzaSyAeP2sdhoQUDcc3HO6uMvuKbC-wQ-TniG4',
  },
  backendUrl: 'https://api2-gl3ps5qzkq-lz.a.run.app',
  home: 'home',
  hasClassifications: false,
  i18n: 'urakka/',
  sentry: 'https://45648da3d75342288a4cce66c64ec484@o4504337135370240.ingest.sentry.io/4505147019558912',
  ios: {
    clientId: 'fi.scandium.urakkaweb',
    redirectURI: 'https://isoharja-demo.web.app/',
  },
  host: 'https://isoharja-demo.web.app',
  functionRegion: 'europe-central2',
};
