import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.dialog.html',
  styleUrls: ['./confirm-dialog.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class ConfirmDialogComponent {

  title: string;
  text: string;
  buttons: string[];

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.title = navParams.get('title');
    this.text = navParams.get('text');
    this.buttons = navParams.get('buttons') ?? [];
  }

  select(index: number) {
    this._modal.dismiss(index);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
