import { CommonModule, DatePipe } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { IonicModule } from '@ionic/angular';
import { Observable } from 'rxjs/internal/Observable';
import { AppCommonModule } from 'src/app/common.module';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { ContentModule } from '../../content/content.module';

export interface CarouselItem<T, R = unknown> {
  guid: string;
  image?: string;
  video?: string;
  logo?: string;
  name: string;
  name2?: string;
  name3?: string;
  text?: string;
  site?: string;
  info?: string;
  preview$?: Observable<string>;
  parent?: T;
  created?: Date | Timestamp;
  date?: Date | Timestamp;
  hours?: number;
  actionName?: string;
  action?: () => void;
  item: R;
  valid?: boolean;
  statusColor?: string;
}

@Component({
  standalone: true,
  selector: 'app-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss'],
  providers: [TranslateTicketPipe, DatePipe],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    ContentModule,
    TranslateTicketPipe,
    TimestampPipe,
  ],
})
export class CarouselItemComponent<T, R> {

  item = input.required<CarouselItem<T, R>>();
  klick = output<CarouselItem<T, R>>();

  previewLoaded = signal(false);
  hasVideo = signal(false);

  onItem(item: CarouselItem<T, R>) {
    this.klick.emit(item);
  }

  onPreview(preview: string) {
    console.log(preview);
    this.hasVideo.set(preview?.includes('_thumbnail.png'));
  }

  onPreviewLoaded(preview: string) {
    this.previewLoaded.set(true);
    this.hasVideo.set(preview?.includes('_thumbnail.png?'));
  }
}
