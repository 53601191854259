/* eslint-disable @typescript-eslint/prefer-for-of */
import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService, StorageService } from '@scandium-oy/ngx-scandium';
import { differenceInMinutes, format } from 'date-fns';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { NewAdhocDialogComponent } from '../components/ad-hoc/new-dialog/new-adhoc.dialog';
import { CarouselItem } from '../components/carousel/item/carousel-item.component';
import { TicketCreatedDialogComponent } from '../components/ticket-created/ticket-created.dialog';
import { Checklist } from '../models/checklist.model';
import { Contractor } from '../models/contractor.model';
import { Room } from '../models/room.model';
import { SiteProjectPeriod } from '../models/site-project-period.model';
import { SiteProject } from '../models/site-project.model';
import { Site } from '../models/site.model';
import { RoomTicket, Ticket, TicketHistory, TicketStatus } from '../models/ticket.model';
import { storageKeys } from '../utility/constants';
import { getAreaTicketGuid, getLeaf } from '../utility/kanban';
import { getUniqueGuid } from '../utility/list';
import { Roles } from '../utility/role';
import { defaultRoom } from '../utility/room';
import { defaultTask, defaultTaskOnSave, getStatusColor } from '../utility/ticket';
import { AreasService } from './areas.service';
import { ChecklistService } from './checklist.service';
import { ContractorsService } from './contractors.service';
import { SitesService } from './sites.service';
import { cleanRoomTicket, TicketsService } from './tickets.service';
import { UsersService } from './users.service';

export interface BasicTicket {
  site: Site;
  room: Room;
  tickets: RoomTicket[];
  parent: Ticket;
  contractor$?: Observable<Contractor>;
}

export interface TicketEdit {
  description?: boolean;
  other?: boolean;
}

export function getRoomName(roomTicket: RoomTicket) {
  if (roomTicket.room == null) {
    return '';
  }
  let ret = '';
  const useRoom = roomTicket.room.siteArea.name.length >= (roomTicket.parent?.areaName?.length ?? 0);
  if (useRoom) {
    if (roomTicket.room.siteArea.name !== defaultRoom) {
      ret = `${roomTicket.room.siteArea.name}`;
    }
  } else {
    if (roomTicket.parent.areaName !== defaultRoom) {
      ret = `${roomTicket.parent.areaName}`;
    }
  }
  if (roomTicket.room.id?.length > 0 && roomTicket.room.id !== defaultRoom) {
    ret += ` / ${roomTicket.room.id}`;
  }
  return ret;
}

export function getRoomNameByRoom(room: Room) {
  if (room == null) {
    return '';
  }
  let ret = '';
  if (room.siteArea && room.siteArea.name !== defaultRoom) {
    ret = `${room.siteArea.name} /`;
  }
  if (room.id != null && room.id !== defaultRoom) {
    ret += ` ${room.id}`;
  }
  return ret;
}

export function getChildAreaName(areaName: string) {
  if (areaName == null) {
    return '';
  }
  const ret = areaName.split('/');
  if (ret.length > 0) {
    return ret[ret.length - 1].trim();
  }
  return areaName;
}

const dateFormat = 'dd.MM.yyyy';

@Injectable({ providedIn: 'root' })
export class WorkerTicketsService {
  private checklistService = inject(ChecklistService);
  private storageService = inject(StorageService);

  private currentUser$ = this.usersService.getCurrentUser();

  private userAreaTickets$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.areasService.getAreaTickets(this.sites$, contractor.guid)),
    shareReplay(1),
  );

  private sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites?.length > 0),
    shareReplay(1),
  );

  private tickets$: Observable<BasicTicket[]> = combineLatest([this.userAreaTickets$, this.currentUser$, this.sites$, this.usersService.role$]).pipe(
    filter(([_, user]) => user != null),
    map(([areaTickets, user, sites, role]) =>
      areaTickets.map((ticket) => {
        const site = sites.find((s) => s.guid === ticket.site);
        const tickets = ticket.tickets
          .filter((rt) => [Roles.admin, Roles.supervisor].includes(role)
            || this.usersService.isSitelead(site, user.guid)
            || rt.users?.includes(user.guid))
          .map((rt) => {
            rt.date = format(rt.history[rt.history.length - 1].timestamp, dateFormat);
            return rt;
          });
        return {
          site, room: ticket.room, tickets, parent: ticket,
        };
      }),
    ),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0 && t.room != null)),
    shareReplay(1),
  );

  created$ = this.tickets$.pipe(
    map((tickets) => [...tickets].map((t) => Object.assign({}, t, {
      tickets: t.tickets.filter((it) => it.status === TicketStatus.created && (!it.additionalWork || it.additionalWorkInfo.adminTimestamp != null)),
    }))),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0)),
    shareReplay(1),
  );

  inProgress$ = this.tickets$.pipe(
    map((tickets) => [...tickets].map((t) => Object.assign({}, t, {
      tickets: t.tickets.filter((it) => it.status === TicketStatus.inProgress),
    }))),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0)),
    shareReplay(1),
  );

  blocked$ = this.tickets$.pipe(
    map((tickets) => [...tickets].map((t) => Object.assign({}, t, {
      tickets: t.tickets.filter((it) => it.status === TicketStatus.blocked),
    }))),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0)),
    shareReplay(1),
  );

  paused$ = this.tickets$.pipe(
    map((tickets) => [...tickets].map((t) => Object.assign({}, t, {
      tickets: t.tickets.filter((it) => it.status === TicketStatus.paused),
    }))),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0)),
    shareReplay(1),
  );

  waitingAcceptance$ = this.tickets$.pipe(
    map((tickets) => [...tickets].map((t) => Object.assign({}, t, {
      tickets: t.tickets.filter((it) => it.status === TicketStatus.created && it.additionalWork && it.additionalWorkInfo?.adminTimestamp == null),
    }))),
    map((tickets) => tickets.filter((t) => t.tickets.length > 0)),
    shareReplay(1),
  );

  constructor(
    private areasService: AreasService,
    private authService: AuthService,
    private contractorsService: ContractorsService,
    private modalCtrl: ModalController,
    private ticketsService: TicketsService,
    private sitesService: SitesService,
    private usersService: UsersService,
  ) { }

  private showToast(ticket: RoomTicket, parent: Ticket, room: Room, site: Site) {
    this.modalCtrl.create({
      component: TicketCreatedDialogComponent,
      componentProps: {
        ticket,
        room,
        parent,
        siteGuid: site.guid,
      },
      cssClass: ['small-modal', 'stack-modal'],
    }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data?.newTicket) {
          this.openSiteCalendarDialog({ site });
        }
      });
    });
  }

  private async checkAndSaveTicketName(name: string) {
    if ([defaultTaskOnSave, defaultTask].includes(name)) {
      return;
    }
    const checklists = await this.storageService.get<Checklist[]>(storageKeys.checklists);
    const list = checklists.find((it) => it.fields?.includes(name)) ?? null;
    if (list == null) {
      this.checklistService.addTranslationField(name);
    }
  }

  async onRoomTicket(roomTicket: RoomTicket, siteGuid: string, room: Room, parent?: Ticket) {
    if (roomTicket.parent) {
      parent = roomTicket.parent;
    }
    if (parent == null) {
      const guid = getAreaTicketGuid(siteGuid, room.siteArea.root, room.siteArea.id, room.id, roomTicket.contractor);
      parent = await firstValueFrom(this.ticketsService.getOnce(guid));
    }
    roomTicket.room = room;
    return this.areasService.openModal(parent, roomTicket);
  }

  getRoomInfo(roomTicket: RoomTicket) {
    return getRoomName(roomTicket);
  }

  createHistory(
    status: TicketStatus | 'assigned' | 'joined' | 'removedBlocked',
    comment?: string,
    images?: string[],
    videos?: string[],
    username?: string,
  ) {
    const user = this.authService.getCurrentUser();
    const displayName = username ?? (this.usersService.currentUserS()?.displayName ?? user.displayName);
    const userGuid = username ? null : user.uid;
    const history: TicketHistory = {
      timestamp: new Date(),
      status,
      username: displayName,
      userGuid,
      images: images ?? [],
      videos: videos ?? [],
    };
    if (comment) {
      history.comment = comment;
    }
    return history;
  }

  calcWorkTime(ticket: RoomTicket) {
    if (![TicketStatus.done, TicketStatus.checked].includes(ticket.status)) {
      return 0;
    }
    let time = 0;
    let start = null;
    ticket.history.forEach((it) => {
      if (it.status === TicketStatus.inProgress) {
        start = it.timestamp;
      } else if (start != null && [TicketStatus.paused, TicketStatus.blocked, TicketStatus.done]) {
        const end = it.timestamp;
        const duration = differenceInMinutes(end, start);
        time += duration;
        start = null;
      }
    });
    return time;
  }

  getCarouselItems(roomTickets: RoomTicket[], extras: string = null, showDates = false) {
    return roomTickets.map((it) => ({
      guid: it.guid,
      name: it.name,
      image: it.images?.length > 0 ? it.images[0] : null,
      preview$: it.preview$,
      site: this.sitesService.getSiteName(it.parent.site),
      info: this.getRoomInfo(it),
      parent: it.parent,
      date: extras != null ? it[extras] : showDates ? it.deadline : null,
      hours: extras != null ? it.hours : undefined,
      statusColor: getStatusColor(it),
      item: it,
      created: showDates ? it.history[0].timestamp : null,
    }) as CarouselItem<Ticket, RoomTicket>);
  }

  filterBasicTickets(
    tickets: BasicTicket[], site: Site, user: string, startDate: Date, endDate: Date,
    project: SiteProject, contractor: Contractor, text: string, littera: { code: string; name: string },
    creator: string, isSecurityObservation: boolean,
  ) {
    return tickets.filter((ticket) => {
      if (site != null) {
        return ticket.site.guid === site.guid;
      } else {
        return true;
      }
    }).filter((ticket) => {
      if (contractor != null) {
        return ticket.parent.contractor === contractor.guid;
      } else {
        return true;
      }
    }).map((ticket) => {
      const clone = Object.assign({}, ticket);
      if (user != null) {
        clone.tickets = clone.tickets?.filter((t) => t.users?.includes(user));
      }
      if (project != null) {
        const filterdTickets = new Set<RoomTicket>();
        project.periods.map((period) => {
          const periodsTickets = clone.tickets
            .filter((t) => (period.areas.some((a) => a.guid === ticket.parent.area) && period.tickets.includes(t.name))
              || period.tickets.includes(t.guid));
          periodsTickets.forEach((t) => filterdTickets.add(t));
        });

        clone.tickets = Array.from(filterdTickets);
      }
      if (startDate != null) {
        clone.tickets = clone.tickets?.filter((t) => t.date >= startDate);
      }
      if (endDate != null) {
        clone.tickets = clone.tickets?.filter((t) => t.date <= endDate);
      }

      if (text?.length > 0) {
        clone.tickets = clone.tickets?.filter((t) => t.name?.toLowerCase().includes(text.toLowerCase()) || t.guid?.toLowerCase().includes(text.toLowerCase()));
      }
      if (littera) {
        clone.tickets = clone.tickets?.filter((t) => t.littera?.code == littera.code);
      }
      if (creator) {
        clone.tickets = clone.tickets?.filter((t) => t.creator === creator);
      }
      if (isSecurityObservation) {
        clone.tickets = clone.tickets?.filter((t) => t.securityObservation);
      }
      return clone;
    });
  }

  saveRoomTickets(data: RoomTicket | RoomTicket[], selectedSite: Site, skipToast?: boolean, cb?: () => void) {
    const user = this.usersService.currentUserS();
    if (Array.isArray(data)) {
      const rooms = new Set(data.map((it) => `${it.room.id}$${it.room.siteArea.id}$${it.room.siteArea.root}$${it.contractor}`));
      const grouped = Array.from(rooms).map((it) => {
        const key = it.split('$');
        if (key[0] === 'null') {
          key[0] = null;
        }
        if ([Roles.manager].includes(user.role) && key[3] === 'null') {
          key[3] = null;
        }
        return {
          key: it,
          tickets: data.filter((itt) => itt.room.id === key[0] && itt.room.siteArea.id === key[1] && itt.room.siteArea.root === key[2] && itt.contractor === key[3]),
        };
      });
      const ret = grouped.map((it, index) => {
        const site = selectedSite ?? it.tickets[0].site;
        const skip = skipToast ? skipToast : index !== 0;
        const parent = this.saveAdhocRoomTicket(it.tickets, site, skip, false, cb);
        return it.tickets.map((t) => ({ guid: t.guid, name: t.name, parent }));
      }).flat();
      return ret;
    } else {
      const site = selectedSite ?? data.site;
      const parent = this.saveAdhocRoomTicket([data], site, skipToast, false, cb);
      return [{ guid: data.guid, name: data.name, parent }];
    }
  }

  async saveRoomTicketsPromise(data: RoomTicket | RoomTicket[], selectedSite: Site, skipToast?: boolean, cb?: () => void) {
    const user = this.usersService.currentUserS();
    if (Array.isArray(data)) {
      const rooms = new Set(data.map((it) => `${it.room.id}$${it.room.siteArea.id}$${it.room.siteArea.root}$${it.contractor}`));
      const grouped = Array.from(rooms).map((it) => {
        const key = it.split('$');
        if (key[0] === 'null') {
          key[0] = null;
        }
        if ([Roles.manager].includes(user.role) && key[3] === 'null') {
          key[3] = null;
        }
        return {
          key: it,
          tickets: data.filter((itt) => itt.room.id === key[0] && itt.room.siteArea.id === key[1] && itt.room.siteArea.root === key[2] && itt.contractor === key[3]),
        };
      });
      const ret: { guid: string; name: string; parent: string }[] = [];
      for (let i = 0; i < grouped.length; i++) {
        const it = grouped[i];
        const site = selectedSite ?? it.tickets[0].site;
        const parent = await this.saveAdhocRoomTicketPromise(it.tickets, site, skipToast ?? i !== 0, false, cb);
        ret.push(...it.tickets.map((t) => ({ guid: t.guid, name: t.name, parent })));
      }
      return ret;
    } else {
      const site = selectedSite ?? data.site;
      const parent = await this.saveAdhocRoomTicketPromise([data], site, skipToast, false, cb);
      return [{ guid: data.guid, name: data.name, parent }];
    }
  }

  async openSiteCalendarDialog(opts:
    {
      site: Site; ticket?: RoomTicket; room?: Room; additionalWork?: true;
      project?: SiteProject; job?: SiteProjectPeriod; sites?: Site[]; setWorker?: boolean; skipToast?: boolean;
    },
  ) {
    return this.modalCtrl.create({
      component: NewAdhocDialogComponent,
      componentProps: {
        site: opts.site, ticket: opts.ticket, room: opts.room, additionalWork: opts.additionalWork,
        project: opts.project, job: opts.job, sites: opts.sites, setWorker: opts.setWorker,
      },
    }).then(async (m) => {
      m.present();
      return m.onDidDismiss<RoomTicket | RoomTicket[]>().then((data) => {
        if (data.data) {
          return this.saveRoomTickets(data.data, opts.site, opts.skipToast);
        }
        return [];
      });
    });
  }

  othersToPaused(guid: string) {
    this.inProgress$.pipe(
      debounceTime(500),
      take(1),
    ).subscribe((inProgress) => {
      inProgress.map(async (it) => {
        const userGuid = this.usersService.currentUserS()?.guid;
        const guids = it.tickets.filter((t) => t.guid != null).map((t) => t.guid);
        const tickets = it.parent.tickets.filter((t) => t.guid !== guid && guids.includes(t.guid) && t.users?.length === 1 && t.users[0] === userGuid);
        tickets.map((t) => {
          t.status = TicketStatus.paused;
          const history = this.createHistory(TicketStatus.paused);
          t.history.push(history);
        });
        await this.ticketsService.updateOnly(it.parent);
      });
    });
  }

  async onTicketSave(parent: Ticket, roomTicket: RoomTicket, edited: TicketEdit = null, updated?: (updatedRoomTicket: RoomTicket) => void) {
    const ticket = Object.assign({}, parent);
    const existing = ticket.tickets.find((t) => t.guid === roomTicket.guid);
    Object.assign(existing, roomTicket);
    return this.ticketsService.updateOnly(ticket).then(() => {
      if (roomTicket.status === TicketStatus.inProgress && roomTicket.guid) {
        this.othersToPaused(roomTicket.guid);
      }
      const index = ticket.tickets.findIndex((it) => it.guid === existing.guid);
      if (existing.history.some((h) => h.comment != null && h.commentText == null)) {
        this.ticketsService.translateField(ticket.guid, index, 'comment', true, false, existing.guid).pipe(
          take(1),
        ).subscribe();
      }
      if (existing.notes?.some((n) => n.textText == null)) {
        this.ticketsService.translateField(ticket.guid, index, 'text', false, true, existing.guid).pipe(
          take(1),
        ).subscribe();
      }
      if (edited?.description) {
        this.ticketsService.translateField(ticket.guid, index, 'description', false, false, existing.guid).pipe(
          take(1),
        ).subscribe(() => {
          if (updated) {
            updated(roomTicket);
          }
        });
      } else if (edited?.other) {
        if (updated) {
          updated(roomTicket);
        }
      }
      if (roomTicket.plainName) {
        this.checkAndSaveTicketName(roomTicket.plainName);
      }
    }, (error) => {
      console.error(error);
    });
  }

  saveAdhocRoomTicket(roomTickets: RoomTicket[], site: Site, skipToast = false, newName = true, cb?: () => void) {
    if (roomTickets.length === 0) {
      return;
    }
    const roomTicket = roomTickets[0];
    const room = roomTicket.room;
    const guid = getAreaTicketGuid(site.guid, room.siteArea.root, room.siteArea.id, room.id, roomTicket.contractor);
    this.ticketsService.getOnce(guid).pipe(
      take(1),
    ).subscribe((ticket) => {
      if (newName) {
        roomTickets.map((it) => {
          const existing = ticket?.tickets.filter((itt) => itt.plainName === it.name || itt.name === it.name)?.length ?? 0;
          it.name = `${it.name} #${existing + 1}`;
        });
      }
      roomTickets.map((it) => cleanRoomTicket(it));
      if (ticket != null) {
        ticket.tickets.push(...roomTickets);
        this.ticketsService.update(ticket).then(() => {
          if (cb) {
            cb();
          }
          if (!skipToast) {
            this.showToast(roomTicket, ticket, room, site);
          }
          roomTickets.map((it) => {
            this.ticketsService.translateField(guid, ticket.tickets.length - 1, 'description', false, false, it.guid).pipe(
              take(1),
            ).subscribe();
            if (it.plainName) {
              this.checkAndSaveTicketName(it.plainName);
            }
          });
        });
      } else {
        ticket = {
          guid,
          tickets: [...roomTickets],
          site: site.guid,
          roomId: room.id ?? null,
          type: room.type ?? 'area',
          area: room.siteArea.root,
          areaName: room.siteArea.name ?? null,
          contractor: roomTicket.contractor,
        };
        this.ticketsService.save(ticket).then(() => {
          if (cb) {
            cb();
          }
          if (!skipToast) {
            this.showToast(roomTicket, ticket, room, site);
          }
          roomTickets.map((it) => {
            this.ticketsService.translateField(guid, 0, 'description', false, false, it.guid).pipe(
              take(1),
            ).subscribe();
            if (it.plainName) {
              this.checkAndSaveTicketName(it.plainName);
            }
          });
        });
      }
    });
    return guid;
  }

  async saveAdhocRoomTicketPromise(roomTickets: RoomTicket[], site: Site, skipToast = false, newName = true, cb?: () => void) {
    if (roomTickets.length === 0) {
      return;
    }
    const roomTicket = roomTickets[0];
    const room = roomTicket.room;
    const guid = getAreaTicketGuid(site.guid, room.siteArea.root, room.siteArea.id, room.id, roomTicket.contractor);
    let ticket = await firstValueFrom(this.ticketsService.getOnce(guid));
    if (newName) {
      roomTickets.map((it) => {
        const existing = ticket?.tickets.filter((itt) => itt.plainName === it.name || itt.name === it.name)?.length ?? 0;
        it.name = `${it.name} #${existing + 1}`;
      });
    }
    roomTickets.map((it) => {
      delete it.room;
      delete it.site;
    });
    if (ticket != null) {
      ticket.tickets.push(...roomTickets);
      await this.ticketsService.update(ticket).then(() => {
        if (cb) {
          cb();
        }
        if (!skipToast) {
          this.showToast(roomTicket, ticket, room, site);
        }
        roomTickets.map((it) => {
          this.ticketsService.translateField(guid, ticket.tickets.length - 1, 'description', false, false, it.guid).pipe(
            take(1),
          ).subscribe();
          if (it.plainName) {
            this.checkAndSaveTicketName(it.plainName);
          }
        });
      });
    } else {
      ticket = {
        guid,
        tickets: [...roomTickets],
        site: site.guid,
        roomId: room.id ?? null,
        type: room.type ?? 'area',
        area: room.siteArea.root,
        areaName: room.siteArea.name ?? null,
        contractor: roomTicket.contractor,
      };
      await this.ticketsService.save(ticket).then(() => {
        if (cb) {
          cb();
        }
        if (!skipToast) {
          this.showToast(roomTicket, ticket, room, site);
        }
        roomTickets.map((it) => {
          this.ticketsService.translateField(guid, 0, 'description', false, false, it.guid).pipe(
            take(1),
          ).subscribe();
          if (it.plainName) {
            this.checkAndSaveTicketName(it.plainName);
          }
        });
      });
    }
    return guid;
  }

  changeRoom(siteGuid: string, roomTicket: RoomTicket, room: Room, cb: () => void) {
    if (roomTicket.guid) {
      const originalGuid = getAreaTicketGuid(siteGuid, roomTicket.room.siteArea.root, roomTicket.room.siteArea.id, roomTicket.room.id, roomTicket.contractor);
      const copy = Object.assign({}, roomTicket);
      copy.room = room;
      this.saveAdhocRoomTicket([copy], { guid: siteGuid } as Site, true, false, cb);
      this.ticketsService.getOnce(originalGuid).pipe(
        take(1),
      ).subscribe((ticket) => {
        ticket.tickets = ticket.tickets.filter((it) => it.guid !== roomTicket.guid);
        this.ticketsService.updateOnly(ticket);
      });
    }
  }

  updateRoomTicket(roomTicket: RoomTicket | RoomTicket[], siteGuid: string, ticketGuid?: string) {
    if (Array.isArray(roomTicket)) {
      const guid = ticketGuid ?? getAreaTicketGuid(siteGuid, roomTicket[0].room.siteArea.root, roomTicket[0].room.siteArea.id, roomTicket[0].room.id, roomTicket[0].contractor);
      this.ticketsService.getOnce(guid).pipe(
        take(1),
      ).subscribe((ticket) => {
        if (ticket != null && roomTicket.every((it) => it.guid != null)) {
          roomTicket.map((rt) => {
            const existing = ticket.tickets.find((it) => it.guid === rt.guid);
            delete existing.room;
            Object.assign(existing, rt);
          });
          this.ticketsService.updateOnly(ticket);
        }
      });
    } else {
      const guid = ticketGuid ?? getAreaTicketGuid(siteGuid, roomTicket.room.siteArea.root, roomTicket.room.siteArea.id, roomTicket.room.id, roomTicket.contractor);
      this.ticketsService.getOnce(guid).pipe(
        take(1),
      ).subscribe((ticket) => {
        if (ticket != null && roomTicket.guid != null) {
          const existing = ticket.tickets.find((t) => t.guid === roomTicket.guid);
          delete existing.room;
          if (existing) {
            Object.assign(existing, roomTicket);
            this.ticketsService.updateOnly(ticket);
          }
        }
      });
    }
  }

  addPeriodToTicket(parentGuid: string, ticketGuid: string, period: string) {
    this.ticketsService.get(parentGuid).pipe(
      take(1),
    ).subscribe((parent) => {
      const ticket = parent.tickets.find((it) => it.guid === ticketGuid);
      ticket.projectPeriod = period;
      this.ticketsService.updateOnly(parent);
    });
  }

  createTicket(contractor: string, site: Site, room: Room, name: string, index?: number) {
    const roomTicket: RoomTicket = {
      guid: getUniqueGuid(index),
      name: name,
      plainName: name,
      phase: 0,
      hours: 0,
      status: TicketStatus.created,
      history: [this.createHistory(TicketStatus.created, undefined, [], [])],
      users: [],
      usernames: [],
      room,
      images: [],
      videos: [],
      attachments: [],
      description: '',
      additionalWork: false, //item.additionalWork,
      additionalWorkInfo: {}, //role === Roles.manager ? { client: this.usersService.currentUserS().displayName, clientTimestamp: new Date() } : {},
      instruction: null,
      deadline: null,
      contractor,
      subcontractor: null,
      creator: this.usersService.currentUserS()?.guid ?? null,
      workMachine: null,
      schedule: null,
      location: null,
      site,
      littera: null,
      planningObservation: false,
      securityObservation: false,
      projectPeriod: null,
    };
    return roomTicket;
  }

  async createTicketsFromTemplate(areas: { guid: string; id: string }[], templateTickets: string[], contractor: string, site: Site) {
    const areas$ = areas.map((a) => this.areasService.getArea(a.guid, a.id));
    const siteAreas = await firstValueFrom(combineLatest(areas$));
    const leafs = siteAreas.map((sa) => {
      return getLeaf(sa).map((leaf) => {
        leaf.guid = sa.guid;
        return leaf;
      });
    }).flat();

    const rooms = leafs.map((it) => ({ id: null, siteArea: { root: it.guid, id: it.id, name: it.name }, type: 'area' } as Room));
    const ret: {
      guid: string;
      name: string;
      parent: string;
    }[] = [];
    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i];
      const roomTickets = templateTickets
        .map((ticket, index) => this.createTicket(contractor, site, room, ticket, index));
      const guids = await this.saveRoomTicketsPromise(roomTickets, site, true);
      ret.push(...guids);
    }
    return ret;
  }

  async deleteRoomTicket(roomTicket: RoomTicket) {
    const ticket = await firstValueFrom(this.getOnce(roomTicket.parent?.guid));
    ticket.tickets = ticket.tickets.filter((t) => t.guid !== roomTicket.guid);
    return this.updateOnly(ticket).then(() => { /**/ }, (error) => {
      console.error(error);
    });
  }

  async deleteRepeatRoomTickets(roomTicket: RoomTicket, site: string) {
    const repeatGuid = roomTicket.repeatGuid;
    if (repeatGuid == null) {
      return;
    }
    const contractor = this.usersService.currentUserS().contractors?.[0] ?? null;
    const tickets = await firstValueFrom(this.getListOnce({ site, contractor }));
    const updates = tickets.map(async (ticket) => {
      ticket.tickets = ticket.tickets.filter((t) => t.repeatGuid !== repeatGuid);
      return this.updateOnly(ticket).then(() => { /**/ }, (error) => {
        console.error(error);
      });
    });
    return Promise.all(updates);
  }

  isReturned(roomTicket: RoomTicket) {
    return roomTicket.history.some((h) => h.comment != null && h.status === TicketStatus.inProgress);
  }

  getOnce(guid: string) {
    return this.ticketsService.getOnce(guid);
  }

  async save(ticket: Ticket) {
    return this.ticketsService.save(ticket);
  }

  async update(ticket: Ticket) {
    return this.ticketsService.update(ticket);
  }

  async updateOnly(ticket: Ticket) {
    return this.ticketsService.updateOnly(ticket);
  }

  getList(options: { contractor?: string; site?: string; area?: string }) {
    return this.ticketsService.getList(options);
  }

  getListOnce(options: { contractor?: string; site?: string; area?: string }) {
    return this.ticketsService.getListOnce(options);
  }
}
