import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const users: MenuSubItem[] = [
  {
    icon: 'people',
    route: '/paycheck/worklist',
    name: 'nav.worklist',
  },
  {
    icon: 'people',
    route: '/logs',
    name: 'nav.notices',
    queryParams: { tabs: 'users' },
  },
];

export const reports: MenuSubItem[] = [
  {
    icon: 'analytics',
    route: '/efficiency',
    name: 'nav.efficiency',
  },
  {
    icon: 'analytics',
    route: '/logs',
    name: 'nav.userNotes',
    queryParams: { tabs: 'sites' },
  },
];

export const extras: MenuSubItem[] = [
  {
    icon: 'cog',
    route: '/templates',
    name: 'nav.templates',
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'albums',
    route: '/clients',
    name: 'nav.clients',
  },
  {
    icon: 'business',
    name: 'nav.sites',
    route: '/worker/sites',
  },
  {
    icon: 'document',
    name: 'sites.tickets',
    route: '/worker/tickets',
  },
  {
    icon: 'people',
    name: 'nav.personel',
    children: users,
  },
  {
    icon: 'newspaper',
    route: '/news',
    name: 'nav.news',
  },
  {
    icon: 'time',
    route: '/report/user',
    name: 'nav.userreportadmin',
  },
  {
    icon: 'cog',
    name: 'nav.extras',
    children: extras,
  },
];

export const supervisorMenu = {
  items,
};
