import { inject, Injectable, signal } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Price } from '@invertase/firestore-stripe-payments';
import { firstValueFrom } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'products';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private firestore = inject(FirestoreService);

  getList(options?: { role: string }) {
    const queryConstraints = [];
    if (options?.role) {
      queryConstraints.push(where('metadata.role', '==', options.role));
    }
    return this.firestore.getList<Product>(itemCollection, undefined, queryConstraints).pipe(
      map((items) => items.map((it) => {
        const prices$ = this.getPrices(it.guid);
        it.currentPrice$ = prices$.pipe(
          filter((prices) => prices?.length > 0),
          map((prices) => prices[0]),
          map((price) => Object.assign({}, price, { price: price.unit_amount ? price.unit_amount / 100 : 0 })),
          shareReplay(1),
        );
        if (it.currentPrice$) {
          it.price = signal(0);
          firstValueFrom(it.currentPrice$).then((price) => {
            it.price?.set(price.price);
          });
        }
        return it;
      })),
    );
  }

  getPrices(guid: string) {
    const queryConstraints = [where('active', '==', true)];
    return this.firestore.getList<Price & { guid: string }>(`${itemCollection}/${guid}/prices`, undefined, queryConstraints);
  }
}
