import { CommonModule } from '@angular/common';
import { Component, ViewChild, computed, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { Room } from 'src/app/models/room.model';
import { SiteProjectPeriod } from 'src/app/models/site-project-period.model';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { ContractorsService } from 'src/app/services/contractors.service';
import { WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { storageKeys } from 'src/app/utility/constants';
import { MainDialogComponent } from '../../main-dialog/main-dialog.component';
import { SegmentListComponent } from '../../segment-list/segment-list.component';
import { AiTasksComponent } from '../ai/ai.component';
import { NewAdhocComponent } from '../new/new-adhoc.component';

@Component({
  standalone: true,
  selector: 'app-new-adhoc-dialog',
  templateUrl: './new-adhoc.dialog.html',
  styleUrls: ['./new-adhoc.dialog.scss'],
  providers: [TranslateTicketPipe],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
    NewAdhocComponent,
    AiTasksComponent,
    SegmentListComponent,
  ],
})
export class NewAdhocDialogComponent {

  @ViewChild(AiTasksComponent)
  aiTasks: AiTasksComponent;

  ticketS = signal<RoomTicket>(null);
  roomS = signal<Room>(null);
  siteS = signal<Site>(null);
  sitesS = signal<Site[]>(null);
  additionalWorkS = signal(false);
  projectS = signal<SiteProject>(null);
  jobS = signal<SiteProjectPeriod>(null);
  setWorkerS = signal(false);

  loaded = signal(false);
  innerBackS = signal(false);

  segment = signal('ai');

  segmentButtons = computed(() => [
    {
      value: 'ai',
      name: 'AI',
      icon: 'color-wand-outline',
      show: this.jobS() == null,
    },
    {
      value: 'ticket',
      name: this.translate.instant('tickets.task'),
      show: true,
    },
  ]);

  constructor(
    private contractorsService: ContractorsService,
    private _modal: ModalController,
    private storageService: StorageService,
    private translate: TranslateService,
    private workerTicketsService: WorkerTicketsService,
    navParams: NavParams,
  ) {
    const ticket = navParams.get<RoomTicket>('ticket');
    const room = navParams.get<Room>('room');
    const site = navParams.get<Site>('site');
    const sites = navParams.get<Site[]>('sites');
    const additionalWork = navParams.get<boolean>('additionalWork');
    const project = navParams.get<SiteProject>('project');
    const job = navParams.get<SiteProjectPeriod>('job');
    const setWorker = navParams.get<boolean>('setWorker');

    this.ticketS.set(ticket);
    this.roomS.set(room);
    this.siteS.set(site);
    this.sitesS.set(sites);
    this.additionalWorkS.set(additionalWork);
    this.projectS.set(project);
    this.jobS.set(job);
    if (job != null) {
      this.segment.set('ticket');
    }
    this.setWorkerS.set(setWorker);

    this.loaded.set(true);
  }

  saved(item?: RoomTicket | RoomTicket[]) {
    this.workerTicketsService.saveRoomTickets(item, null, true);
    const contractor = this.contractorsService.contractorS();
    if (contractor != null) {
      const ai = { languages: contractor.ai?.languages ?? [], tasks: contractor.ai?.tasks ?? 0, prompts: contractor.ai?.prompts ?? 0 };
      ai.prompts++;
      this.contractorsService.updateAiConfig(contractor.guid, ai);
    }
  }

  setHasInner(value: boolean) {
    this.innerBackS.set(value);
  }

  dismiss(item?: RoomTicket | RoomTicket[]) {
    if (this.innerBackS()) {
      this.aiTasks.emptySelectedTicket();
    } else {
      if (item != null) {
        const contractor = this.contractorsService.contractorS();
        if (contractor != null) {
          const ai = { languages: contractor.ai?.languages ?? [], tasks: contractor.ai?.tasks ?? 0, prompts: contractor.ai?.prompts ?? 0 };
          if (Array.isArray(item)) {
            ai.tasks += item.length;
          } else {
            ai.tasks++;
          }
          this.contractorsService.updateAiConfig(contractor.guid, ai);
        }
      }
      this.storageService.removeItem(storageKeys.newAdhoc);
      this._modal.dismiss(item);
    }
  }
}
