import { MenuSubItem } from './menu-subitem.model';
import { MenuItem } from './menu.item.model';

export const products: MenuSubItem[] = [
  {
    icon: 'cube',
    route: '/materials/order',
    name: 'nav.materials.order',
  },
  {
    icon: 'cube',
    route: '/materials/process',
    name: 'nav.materials.process',
  },
];

export const items: MenuItem[] = [
  {
    icon: 'home',
    name: 'nav.home',
    route: '/home',
  },
  {
    icon: 'diamond',
    name: 'nav.kanban',
    route: '/week-user',
  },
  {
    icon: 'cube',
    name: 'nav.materials.title',
    children: products,
  },
  {
    icon: 'newspaper',
    name: 'nav.news',
    route: '/worker/messages',
  },
  {
    icon: 'calendar',
    name: 'nav.userreportworker',
    route: '/report/user',
  },
  {
    icon: 'calendar-number-outline',
    name: 'nav.workingCalendar',
    route: '/user-week-calendar',
  },
  {
    icon: 'cash',
    name: 'nav.userpaycheckworker',
    route: '/worker/paycheck',
  },
];

export const workerMenu = {
  items,
};
