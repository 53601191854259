import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { Room } from 'src/app/models/room.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';

@Component({
  standalone: true,
  selector: 'app-ticket-created-dialog',
  templateUrl: './ticket-created.dialog.html',
  styleUrls: ['./ticket-created.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class TicketCreatedDialogComponent {
  private router = inject(Router);
  private workerTicketsService = inject(WorkerTicketsService);

  private ticket: RoomTicket;
  private siteGuid: string;
  private room: Room;

  constructor(
    private _modal: ModalController,
    private navigationService: NavigationService,
    navParams: NavParams,
  ) {
    this.ticket = navParams.get('ticket');
    this.siteGuid = navParams.get('siteGuid');
    this.room = navParams.get('room');
  }

  toTicket() {
    this.workerTicketsService.onRoomTicket(this.ticket, this.siteGuid, this.room).then(async () => this._modal.dismiss());
  }

  toTicketList() {
    const url = this.router.url;
    if (url.includes('/sites/site')) {
      this.navigationService.updateQueryParams({ tabs: 'tickets' });
      this.navigationService.setSelectedTab('tickets');
      this._modal.dismiss();
    } else {
      this.navigationService.navigate(['/worker', 'tickets'], { queryParams: { site: this.siteGuid, tab: 'open' } }).then(async () => this._modal.dismiss());
    }
  }

  newTicket() {
    this._modal.dismiss({ newTicket: true });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
