import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { isAfter, isBefore } from 'date-fns';
import { Observable, combineLatest, defer, from, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { KanbanItemViewDialogComponent } from '../kanban/view/item/item-view.dialog';
import { Room } from '../models/room.model';
import { SiteArea } from '../models/site-area.model';
import { SiteProject } from '../models/site-project.model';
import { Site } from '../models/site.model';
import { RoomTicket, Ticket, TicketStatus } from '../models/ticket.model';
import { getThumb, getVideoThumb } from '../utility/image';
import { findParent, getAreaTicketItems, getLeaf, parseAreaTicketGuid, setSiteArea } from '../utility/kanban';
import { deepClone } from '../utility/object';
import { Roles } from '../utility/role';
import { getHours } from '../utility/room';
import { ContractorsService } from './contractors.service';
import { SiteAreasService } from './site-areas.service';
import { TicketsService } from './tickets.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class AreasService {

  constructor(
    private contractorsService: ContractorsService,
    private modalCtrl: ModalController,
    private siteAreasService: SiteAreasService,
    private ticketsService: TicketsService,
    private usersService: UsersService,
  ) { }

  private getRoomFromArea(area: SiteArea, roomId: string, childAreaGuid: string) {
    let room: Room = null;
    let retArea: SiteArea = null;
    if ((childAreaGuid == null || area.id === childAreaGuid) && area.rooms?.length > 0) {
      room = area.rooms.find((r) => r.id === roomId);
      retArea = deepClone(area);
    } else if (area.children?.length > 0) {
      area.children.map((c) => {
        if (room === null) {
          const childRoom = this.getRoomFromArea(c, roomId, childAreaGuid).room;
          if (childRoom !== null) {
            room = childRoom;
            retArea = deepClone(c);
          } else if (roomId === '' && childAreaGuid === c.id) {
            retArea = deepClone(c);
          }
        }
      });
    }
    return { room, area: retArea };
  }

  private getAreaFromArea(area: SiteArea, childAreaGuid: string) {
    let retArea: SiteArea = null;
    if (childAreaGuid == null || area.id === childAreaGuid) {
      retArea = deepClone(area);
    } else if (area.children?.length > 0) {
      area.children.map((c) => {
        if (retArea === null) {
          const childArea = this.getAreaFromArea(c, childAreaGuid);
          if (childArea !== null) {
            retArea = deepClone(c);
          }
        }
      });
    }
    return retArea;
  }

  getPreview(rt: RoomTicket) {
    if (rt.images?.length > 0 && rt.preview$ == null) {
      return defer(() => from(getThumb(rt.images[0]))).pipe(
        map((thumb) => thumb?.thumb ?? rt.images[0]),
        shareReplay(1),
      );
    } else if (rt.videos?.length > 0 && rt.preview$ == null) {
      return from(getVideoThumb(rt.videos[0])).pipe(
        map((thumb) => thumb?.thumb ?? rt.videos[0]),
        shareReplay(1),
      );
    } else {
      return of(null);
    }
  }

  getArea(guid: string, id: string) {
    return this.siteAreasService.get(guid).pipe(
      map((root) => this.getAreaFromArea(root, id)),
    );
  }

  getAreas(site$: Observable<Site>): Observable<SiteArea[]> {
    return site$.pipe(
      switchMap((site) => this.siteAreasService.getList({ site: site.guid })),
      shareReplay(1),
    );
  }

  getSiteAreas(site: Site): Observable<SiteArea[]> {
    return this.siteAreasService.getList({ site: site.guid }).pipe(
      shareReplay(1),
    );
  }

  getAreaTickets(sites$: Observable<{ guid?: string }[]>, contractor?: string): Observable<Ticket[]> {
    const user = this.usersService.currentUserS();
    if (!user) {
      console.error('No user');
      return;
    }
    let sitesGuids: string[] = [];
    if ([Roles.manager].includes(user.role)) {
      // const contractors = contractor ? [contractor] : [...user.contractors, Roles.manager];
      return sites$.pipe(
        map((sites) => sites.map((site) => this.siteAreasService.getList({ site: site.guid }))),
        switchMap((areas) => combineLatest(areas)),
        debounceTime<SiteArea[][]>(250),
        map((areas) => areas.flat()),
        switchMap((areas) => combineLatest([this.ticketsService.getList({ site: areas[0].site }), of(areas)])),
        debounceTime(500),
        map(([tickets, areas]) => tickets.map((it) => {
          const root = areas.find((a) => a.guid === it.area);
          it.room = root ? this.getRoom(it.guid, root) : null;
          it.tickets = it.tickets.map((rt) => {
            rt.preview$ = this.getPreview(rt);
            return rt;
          });
          return it;
        })),
        startWith<Ticket[]>([]),
      );
    } else if ([Roles.superAdmin].includes(user.role)) {
      return sites$.pipe(
        map((sites) => sites.map((site) => this.siteAreasService.getList({ site: site.guid }))),
        switchMap((areas) => combineLatest(areas)),
        debounceTime<SiteArea[][]>(250),
        map((areas) => areas.flat()),
        switchMap((areas) => combineLatest([this.ticketsService.getList({ site: areas[0].site }), of(areas)])),
        debounceTime(500),
        map(([tickets, areas]) => tickets.map((it) => {
          const root = areas.find((a) => a.guid === it.area);
          it.room = root ? this.getRoom(it.guid, root) : null;
          it.tickets = it.tickets.map((rt) => {
            rt.preview$ = this.getPreview(rt);
            return rt;
          });
          return it;
        })),
        startWith<Ticket[]>([]),
      );
    } else {
      const contractorGuid = contractor ?? this.contractorsService.contractorS()?.guid;
      return sites$.pipe(
        tap((sites) => sitesGuids = sites.map((s) => s.guid)),
        map((sites) => sites.map((site) => this.siteAreasService.getList({ site: site.guid }))),
        switchMap((areas) => combineLatest(areas)),
        debounceTime<SiteArea[][]>(250),
        map((areas) => areas.flat()),
        map((areas) => ({ ticketGuids: getAreaTicketItems(areas, contractorGuid), areas })),
        filter((item) => item.ticketGuids.length > 0),
        switchMap((item) => {
          const batches: Observable<Ticket[]>[] = [];
          while (sitesGuids.length) {
            const batch = sitesGuids.splice(0, 30);
            batches.push(this.ticketsService.getList({ contractor: contractorGuid, sites: batch }));
          }
          return combineLatest([
            of(item.areas),
            ...batches,
          ]);
        }),
        debounceTime<[SiteArea[], Ticket[]]>(250),
        // tap(([areas, tickets]) => console.log('Areas', areas.length, 'Tickets', tickets?.length)), // DEBUG
        map(([areas, tickets]) => tickets?.flat().map((it) => {
          const root = areas.find((a) => a.guid === it.area);
          it.room = root ? this.getRoom(it.guid, root) : null;
          it.tickets = it.tickets.map((rt) => {
            rt.preview$ = this.getPreview(rt);
            return rt;
          });
          return it;
        }) ?? []),
        startWith<Ticket[]>([]),
      ) as Observable<Ticket[]>;
    }
  }

  getRoomsFromAreas(areas: SiteArea[]) {
    return areas.map((area) => {
      const leafs = getLeaf(area);
      return leafs.map((it) => it.rooms.map((room) => setSiteArea(room, area, it))).flat();
    }).flat();
  }

  getAreaRooms(site$: Observable<Site>): Observable<Room[]> {
    return site$.pipe(
      switchMap((site) => this.siteAreasService.getList({ site: site.guid })),
      map((areas) => this.getRoomsFromAreas(areas)),
    );
  }

  getRoom(ticketGuid: string, root: SiteArea): Room {
    const parsed = parseAreaTicketGuid(ticketGuid);
    const parent = findParent(root, parsed.childAreaGuid);
    const { room, area } = this.getRoomFromArea(root, parsed.roomId, parsed.childAreaGuid);
    if (room == null) {
      return {
        id: parsed.roomId ?? null,
        siteArea: {
          root: root.guid,
          id: parsed.childAreaGuid ?? parent.id,
          name: root.name !== area?.name ? `${root.name} / ${area?.name ?? ''}` : `${parent.name}`,
          childAreaName: area?.name,
        },
        type: 'common',
      };
    }
    room.siteArea = {
      root: root.guid,
      id: parent.id,
      name: root.name !== area?.name ? `${root.name} / ${area?.name ?? ''}` : `${parent.name}`,
    };
    return room;
  }

  getRoomId(room: string | Room) {
    if (typeof room === 'string') {
      return room;
    }
    return room.id;
  }

  findRoom(room: string | Room, area: SiteArea): Room {
    const roomName = this.getRoomId(room);
    if (area.children?.length > 0) {
      const children = area.children.map((child) => this.findRoom(roomName, child)).filter((it) => it != null);
      if (children.length > 0) {
        return children[0];
      }
    } else if (area.rooms) {
      return area.rooms.find((it) => it.id.toLowerCase() === roomName.toLowerCase());
    }
    return null;
  }

  findArea(areaName: string, area: SiteArea): SiteArea {
    if (area.name.toLowerCase() === areaName.toLowerCase()) {
      return area;
    } else if (area.children?.length > 0) {
      const children = area.children.map((child) => this.findArea(areaName, child)).filter((it) => it != null);
      if (children.length > 0) {
        return children[0];
      }
    }
    return null;
  }

  getSiteReadiness(site$: Observable<Site>) {
    const areaTickets$ = site$.pipe(
      switchMap((site) => this.getAreaTickets(of([site]))),
      map((tickets) => tickets.map((ticket) => getHours(ticket?.tickets ?? []))),
    );
    return combineLatest([areaTickets$]).pipe(
      map(([areaTickets]) => {
        const percentages = [...areaTickets];
        const all = percentages.reduce((prev, curr) => prev + curr.all, 0);
        const done = percentages.reduce((prev, curr) => prev + curr.done, 0);
        return (done / all) * 100;
      }),
    );
  }

  getProjectReadiness(project: SiteProject) {
    return this.getAreaTickets(of([{ guid: project.site }]), project.contractor).pipe(
      map((areaTickets) => {
        const periodTickets: RoomTicket[] = [];

        const periods = project.periods.map((period) => areaTickets
          .filter((ticket) => period.areas.some((it) => it.guid === ticket.area))
          .map((ticket) => {
            const tickets = ticket.tickets.filter((t) => period.tickets.includes(t.name) || period.tickets.includes(t.guid));
            periodTickets.push(...tickets);
            return { period: period.name, percentage: this.getPercentage(tickets) };
          }),
        ).flat();

        return { periods, project: this.getPercentage(periodTickets) };
      }),
    );
  }

  getPercentage(roomTickets: RoomTicket[]) {
    const hours = getHours(roomTickets ?? []);
    const percentages = [hours];
    const all = percentages.reduce((prev, curr) => prev + curr.all, 0);
    const done = percentages.reduce((prev, curr) => prev + curr.done, 0);
    return (done / all) * 100;
  }

  getUserTickets(tickets: Ticket[], userGuid: string, status?: TicketStatus, startDate?: Date, endDate?: Date) {
    const roomTickets = tickets.map((it) => it.tickets).flat();
    const usersTickets = roomTickets
      .filter((it) => it.users?.includes(userGuid))
      .filter((it) => status ? it.status === status : true)
      .map((it) => {
        const done = it.history.find((itt) => itt.status === TicketStatus.done);
        it.date = done?.timestamp;
        return it;
      })
      .filter((it) => startDate && endDate ? !isAfter(it.date, endDate) && !isBefore(it.date, startDate) : true);
    return usersTickets;
  }

  getPlanningObservations(site: Site) {
    return combineLatest([this.ticketsService.getList({ site: site.guid, contractor: null }), this.getAreas(of(site))]).pipe(
      map(([tickets, areas]) => tickets.map((it) => {
        const root = areas.find((a) => a.guid === it.area);
        it.room = root ? this.getRoom(it.guid, root) : null;
        return it;
      })),
    );
  }

  openRoomTicket(parentGuid: string, roomTicketGuid: string, setWorker = false) {
    const parsedGuid = parseAreaTicketGuid(parentGuid);
    combineLatest([this.ticketsService.get(parentGuid), this.siteAreasService.get(parsedGuid.areaGuid)]).pipe(
      take(1),
    ).subscribe(([parent, area]) => {
      const roomTicket = parent.tickets.find((it) => it.guid === roomTicketGuid);
      roomTicket.room = this.getRoom(parentGuid, area);
      this.openModal(parent, roomTicket, setWorker);
    });
  }

  async openModal(parent: Ticket, roomTicket: RoomTicket, setWorker = false) {
    return this.modalCtrl.create({
      component: KanbanItemViewDialogComponent,
      componentProps: {
        ticket: roomTicket,
        room: roomTicket.room,
        parent,
        siteGuid: parent.site,
        setWorker,
      },
    }).then((m) => {
      m.present();
    });
  }
}
