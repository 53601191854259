<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ title }}
  </div>
  <div [innerHTML]="text | translate"
    class="ion-padding"></div>
  <div class="flex ion-margin">
    @for (button of buttons; track button; let i = $index) {
    <div>
      <ion-button color="light"
        shape="round"
        (click)="select(i)">
        {{ button | translate }}
      </ion-button>
    </div>
    }
  </div>
</app-main-dialog>