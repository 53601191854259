import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { LogoModule, fieldSorter } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Client } from 'src/app/models/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { NewClientDialogComponent } from '../new-client/new-client.dialog';

@Component({
  standalone: true,
  selector: 'app-select-client-dialog',
  templateUrl: './select-client.dialog.html',
  styleUrls: ['./select-client.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    LogoModule,
    MainDialogComponent,
  ],
})
export class SelectClientDialogComponent {

  showAllClients = signal(false);

  private clients$ = this.contractorsService.getCurrentContractor().pipe(
    switchMap((contractor) => this.clientsService.getListFromDb(null).pipe(
      map((clients) => clients.filter((it) => it.contractor == null || contractor?.guid === it.contractor || this.usersService.role() === Roles.superAdmin)),
      map((clients) => clients.filter((it) => !contractor?.clients.includes(it.guid))),
      map((clients) => clients.sort(fieldSorter(['name']))),
    )),
    shareReplay(1),
  );

  filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  currentClients$ = combineLatest([this.filter$, this.clientsService.getList()]).pipe(
    map(([filterValue, clients]) => clients.filter((client) =>
      client.guid.toLowerCase().includes(filterValue.toLowerCase())
      || client.name.toLowerCase().includes(filterValue.toLowerCase()))),
    map((clients) => clients.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  filteredClients$ = combineLatest([this.filter$, this.clients$]).pipe(
    map(([filterValue, clients]) => clients.filter((client) =>
      client.guid.toLowerCase().includes(filterValue.toLowerCase())
      || client.name.toLowerCase().includes(filterValue.toLowerCase()))),
    shareReplay(1),
  );

  selected = signal<Client>(null);

  constructor(
    private clientsService: ClientsService,
    private contractorsService: ContractorsService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private usersService: UsersService,
    navParams: NavParams,
  ) {
    this.showAllClients.set(navParams.get('isNewSite') ?? false);
  }

  onChange(event: CustomEvent) {
    this.filter$.next(event.detail.value);
    this.selected.set(null);
  }

  addNew() {
    this.modalCtrl.create({ component: NewClientDialogComponent }).then((m) => {
      m.present();

      m.onDidDismiss<Client>().then((data) => {
        if (data.data) {
          this.clientsService.save(data.data).then(() => this.dismiss(data.data));
        }
      });
    });
  }

  select(item: Client) {
    if (this.selected()?.guid === item.guid) {
      this.selected.set(null);
    } else {
      this.selected.set(item);
    }
  }

  save(item?: Client) {
    if (item) {
      this.dismiss(item);
    } else {
      this.dismiss(this.selected());
    }
  }

  dismiss(item?: Client) {
    this._modal.dismiss(item);
  }
}
