import { CommonModule } from '@angular/common';
import { Component, Input, computed, inject, input, output, signal } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { IonInput, IonicModule, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraButtonModule, FileUpload, ImageDialogComponent, LoadingModule } from '@scandium-oy/ngx-scandium';
import { QueueItem } from '@scandium-oy/ngx-scandium/lib/services/queue.service';
import { minutesToHours, startOfDay, startOfToday } from 'date-fns';
import { ReplaySubject, defer, from } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { AttachmentsComponent } from 'src/app/components/attachments/attachments.component';
import { BlueprintDialogComponent } from 'src/app/components/blueprint/blueprint.dialog';
import { CarouselImage } from 'src/app/components/carousel/images/carousel-image.model';
import { ImageComponent, ImageItem } from 'src/app/components/images/image.component';
import { ImagesModule } from 'src/app/components/images/images.module';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { SelectAreaDialogComponent } from 'src/app/components/select-area/select-area.dialog';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectLitteraDialogComponent } from 'src/app/components/select-littera/select-littera.dialog';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.dialog';
import { Room } from 'src/app/models/room.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { RoomTicket, TicketHistory, TicketNote, TicketStatus } from 'src/app/models/ticket.model';
import { User } from 'src/app/models/user.model';
import { WorkMachine } from 'src/app/models/work-machine.model';
import { NotesModule } from 'src/app/notes/notes.module';
import { TranslateFieldPipe } from 'src/app/pipes/field-translate/field-translate.pipe';
import { TranslateTicketPipe } from 'src/app/pipes/ticket-translate/ticket-translate.pipe';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { InstructionsLinkService } from 'src/app/services/instructions-link.service';
import { LicenseService } from 'src/app/services/licence.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkerTicketsService } from 'src/app/services/worker-tickets.service';
import { WorkersService } from 'src/app/services/workers.service';
import { getThumb } from 'src/app/utility/image';
import { deepClone } from 'src/app/utility/object';
import { Roles } from 'src/app/utility/role';
import { defaultRoom } from 'src/app/utility/room';
import { fieldSorter, sortAnyByDate } from 'src/app/utility/sort';
import { timeArray } from 'src/app/utility/time';
import { AcceptTicketDialogComponent } from '../feed/dialog/accept-ticket.dialog';
import { BlockButtonComponent } from './component/block-button.component';
import { TicketHelpButtonComponent } from './component/help-button.component';
import { FreeSelectWorkerComponent } from './component/select-worker/select-worker.component';
import { AddphotosDialogComponent } from './dialog/add-photos.dialog';

interface IHistory {
  text: string;
  comment?: string;
  commentText?: Record<string, string>;
  username: string;
  timestamp: Date;
  label?: string;
  labelColor?: string;
  status?: string;
  images?: string[];
  videos?: string[];
  blockedBy?: SubItem;
  userImage?: string;
}

@Component({
  standalone: true,
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [
    TranslateFieldPipe,
    TranslateTicketPipe,
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NotesModule,
    CameraButtonModule,
    ImagesModule,
    TakeVideoComponent,
    TranslateTicketPipe,
    LoadingModule,
    SelectDayComponent,
    AttachmentsComponent,
    BlockButtonComponent,
    TicketHelpButtonComponent,
    TranslateFieldPipe,
    SegmentListComponent,
    FreeSelectWorkerComponent,
    ImageComponent,
  ],
})
export class TicketComponent {
  private sitesService = inject(SitesService);
  private siteGuidS = signal<string>(null);

  siteGuid$ = new ReplaySubject<string>(1);
  history = signal<IHistory[]>([]);
  ticket: RoomTicket;
  canJoin = signal(false);
  asked = signal(false);
  canDelete = signal(false);
  readonly defaultRoom = defaultRoom;

  @Input()
  set siteGuid(value: string) {
    this.siteGuid$.next(value);
    this.siteGuidS.set(value);
  }

  @Input()
  set roomTicket(value: RoomTicket) {
    this.ticket = value;
    const currentUser = this.usersService.currentUserS();
    if (currentUser?.role === Roles.worker && !this.isSiteLead()) {
      this.canJoin.set(value.users == null || value.users.length === 0 || value.users?.includes(currentUser.guid));
      this.asked.set(value.requests?.find((it) => it.guid === currentUser.guid) != null);
    } else {
      this.canJoin.set(true);
    }
    this.canDelete.set([Roles.admin, Roles.superAdmin].includes(currentUser.role) || (value.users?.includes(currentUser.guid) && value.status !== TicketStatus.checked));
    this.updateHistory(value);
    const time = this.workerTicketsService.calcWorkTime(value);
    this.ticket.usedTimeInH = minutesToHours(time);
    this.instructionsLinkService.get({ ticket: value.name }).pipe(
      filter((link) => link != null),
      take(1),
    ).subscribe((link) => this.ticket.instruction = link.instruction);
    if (value?.images || value?.videos) {
      const images: ImageItem[] = value.images?.map((url) => ({
        image: url, preview$: defer(() => from(getThumb(url))).pipe(
          map((thumb) => thumb?.thumb ?? url),
          shareReplay(1),
        ),
      })) ?? [];
      this.images.set(images);
      const videos: CarouselImage[] = value.videos?.map((url) => ({ url, type: 'video' })) ?? [];
      this.videos.set(videos);
    }
  }

  isSiteLead = input(false);
  room = input.required<Room>();
  parentGuid = input.required<string>();

  roomChanged = output<Room>();
  save = output<RoomTicket>();
  help = output<string>();
  delete = output<RoomTicket>();
  deleteAll = output<RoomTicket>();
  dismiss = output();
  segment = output<string>();

  readonly pickerColumns = timeArray;

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  user = computed(() => this.usersService.currentUserS());

  readonly statuses = TicketStatus;
  readonly today = startOfToday().toISOString();

  isFreeLicence$ = this.licenseService.isFreeLicence().pipe(
    shareReplay(1),
  );

  users$ = this.role$.pipe(
    filter((role: Roles) => [Roles.admin, Roles.superAdmin, Roles.manager].includes(role) || this.isSiteLead()),
    switchMap(() => this.siteGuid$.asObservable()),
    switchMap((site) => this.workersService.getWorkers(site)),
    map((users) => users.sort(fieldSorter(['displayName']))),
    map((users) => users.map((u) => ({ guid: u.guid, name: u.displayName, selected: this.ticket.users?.includes(u.guid) }))),
    shareReplay(1),
  );

  segmentValue = signal<string>('main');
  images = signal<ImageItem[]>([]);
  videos = signal<CarouselImage[]>([]);

  siteClassification$ = this.siteGuid$.pipe(
    filter((guid) => guid != null),
    switchMap((guid) => this.sitesService.get(guid)),
    map((site) => site.classification),
    shareReplay(1),
  );

  segmentButtons = [
    {
      value: 'main',
      name: 'calendar.site.name',
      show: true,
    },
    {
      value: 'attachments',
      name: 'site.attachments',
      show: true,
    },
    {
      value: 'settings',
      name: 'nav.materials.manage',
      show: true,
    },
    {
      value: 'images',
      name: 'site.images',
      show: true,
    },
    /*{
      value: 'productInfo',
      name: 'tickets.productInfo',
      show: true,
    },*/
  ];

  constructor(
    private contractorsService: ContractorsService,
    private instructionsLinkService: InstructionsLinkService,
    private licenseService: LicenseService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private translateField: TranslateFieldPipe,
    private usersService: UsersService,
    private workersService: WorkersService,
    private workerTicketsService: WorkerTicketsService,
  ) { }

  private getUserImage(username: string) {
    return this.usersService.getByUsername(username)?.image ?? null;
  }

  private updateHistory(ticket: RoomTicket) {
    const ticketHistory: IHistory[] = ticket.history.map((it) => {
      if (it.status === TicketStatus.inProgress && it.backFromBlocked) {
        it.status = 'removedBlocked';
      }
      const translateText = `kanban.logEvent.${it.status}`;
      const text = this.translate.instant(translateText,
        {
          additionalWork: ticket.blockedBy ? this.translate.instant('kanban.logEvent.additionalWork') : '',
        });
      return {
        text,
        timestamp: it.timestamp,
        username: it.username,
        userImage: this.getUserImage(it.username),
        comment: it.comment,
        commentText: it.commentText,
        label: [TicketStatus.done, TicketStatus.blocked].includes(it.status as TicketStatus) ? it.status : 'log',
        labelColor: it.status === TicketStatus.done ? 'success' : it.status === TicketStatus.blocked ? 'danger' : 'medium',
        status: it.status,
        images: it.images,
        videos: it.videos,
        blockedBy: it.status === TicketStatus.blocked ? ticket.blockedBy : null,
      };
    }) ?? [];
    const ticketNotes: IHistory[] = ticket.notes?.filter((it) => !it.isAdHoc).map((it) => ({
      text: it.textText != null ? this.translateField.transform(it.textText) : it.text,
      timestamp: it.timestamp,
      username: it.username,
      userImage: this.getUserImage(it.username),
      label: 'note',
      labelColor: 'light',
    })) ?? [];
    this.history.set([...ticketHistory, ...ticketNotes].sort((a, b) => sortAnyByDate(a, b, 'timestamp')));
    if (ticket.additionalWorkInfo) {
      const admin = {
        text: this.translate.instant('kanban.logEvent.adminAccept'),
        timestamp: ticket.additionalWorkInfo.adminTimestamp,
        username: ticket.additionalWorkInfo.admin,
        userImage: this.getUserImage(ticket.additionalWorkInfo.admin),
      };
      if (admin.timestamp) {
        this.history.update((val) => [...val, admin]);
      }
      const client = {
        text: this.translate.instant('kanban.logEvent.clientAccept'),
        timestamp: ticket.additionalWorkInfo.clientTimestamp,
        username: ticket.additionalWorkInfo.client,
        userImage: this.getUserImage(ticket.additionalWorkInfo.client),
      };
      if (client.timestamp) {
        this.history.update((val) => [...val, client]);
      }
      this.history.update((value) => value.sort((a, b) => sortAnyByDate(a, b, 'timestamp')));
    }
  }

  private _save(ticket: RoomTicket) {
    this.updateHistory(ticket);
    this.save.emit(ticket);
  }

  private saveTicket(options: {
    status: TicketStatus;
    comment?: string;
    images?: string[];
    videos?: string[];
    blockedBy?: SubItem;
    onlyImages?: boolean;
    backFromBlocked?: boolean;
  },
  ) {
    if (options.onlyImages) {
      if (options.images?.length > 0) {
        if (this.ticket.images == null) {
          this.ticket.images = [];
        }
        this.ticket.images.push(...options.images);
      }
      if (options.videos?.length > 0) {
        if (this.ticket.videos == null) {
          this.ticket.videos = [];
        }
        this.ticket.videos.push(...options.videos);
      }
      this.ticket.room = Object.assign({}, this.room());
      this.workerTicketsService.updateRoomTicket(this.ticket, this.siteGuidS());
      return;
    }
    let history: TicketHistory;
    if (options.backFromBlocked) {
      history = this.workerTicketsService.createHistory('removedBlocked', options.comment, options.images, options.videos);
    } else {
      history = this.workerTicketsService.createHistory(options.status, options.comment, options.images, options.videos);
    }
    this.ticket.history.push(history);
    if (options.images?.length > 0) {
      if (this.ticket.images == null) {
        this.ticket.images = [];
      }
      this.ticket.images.push(...options.images);
    }
    if (options.videos?.length > 0) {
      if (this.ticket.videos == null) {
        this.ticket.videos = [];
      }
      this.ticket.videos.push(...options.videos);
    }
    if (options.blockedBy) {
      this.ticket.blockedBy = options.blockedBy;
    }
    if (options.status !== TicketStatus.checked && options.comment == null) {
      const user = this.usersService.currentUserS();
      if (![Roles.manager, Roles.superAdmin].includes(user.role)) {
        const displayName = user.displayName;
        if (this.ticket.users?.length > 0) {
          if (!this.ticket.users.includes(user.guid)) {
            this.ticket.users.push(user.guid);
            this.ticket.usernames.push(displayName);
          }
        } else {
          this.ticket.users = [user.guid];
          this.ticket.usernames = [displayName];
        }
      }
    }
    this.ticket.status = options.status;
    this._save(this.ticket);
  }

  private async takePhotos(status: TicketStatus) {
    const contractor = this.contractorsService.contractorS();
    const imageOptions = contractor?.siteOptions?.find((it) => it.site === this.siteGuidS())?.images ?? 'after';
    const required = (status === TicketStatus.inProgress && ['before', 'always'].includes(imageOptions))
      || (status === TicketStatus.done && ['after', 'always'].includes(imageOptions))
      || (status === TicketStatus.blocked && ['always'].includes(imageOptions));

    const cb = (imageUrl: string) => {
      this.saveTicket({ status: null, onlyImages: true, images: [imageUrl] });
    };
    cb.bind(this);
    return this.modalCtrl.create({
      component: AddphotosDialogComponent,
      componentProps: { required, cb, mediaItems: (this.ticket.images?.length ?? 0) + (this.ticket.videos?.length ?? 0) },
      cssClass: ['modal-fullscreen', 'transparent-modal'],
    }).then(async (m) => {
      m.present();

      return m.onDidDismiss<{ images: string[]; videos: string[]; items: QueueItem<FileUpload>[] }>().then((data) => {
        if (data.data?.items.length > 0) {
          return { data: { images: [], videos: [] }, role: data.role };
        }
        return data;
      });
    });
  }

  segmentChanged(value: string) {
    this.segmentValue.set(value);
    this.segment.emit(value);
  }

  onImage(image: string) {
    this.modalCtrl.create({
      component: ImageDialogComponent,
      componentProps: { image, images: this.ticket.images },
      cssClass: ['modal-fullscreen', 'transparent-modal'],
    }).then((modal) => {
      modal.present();
    });
  }

  showLocation(room: Room | string) {
    const blueprint = typeof room === 'string' ? room : room.blueprint;
    const title = typeof room === 'string' ? this.translate.instant('calendar.site.location') : `${room.siteArea?.name} / ${room.id}`;
    this.modalCtrl.create({
      component: BlueprintDialogComponent,
      componentProps: { blueprint, title },
      cssClass: 'canvas-modal',
    }).then(async (m) => m.present());
  }

  selectRoom() {
    const areas = [{ siteArea: this.ticket.room.siteArea }];
    this.modalCtrl.create({ component: SelectAreaDialogComponent, componentProps: { site: this.siteGuidS(), areas } }).then((m) => {
      m.present();

      m.onDidDismiss<Room>().then((data) => {
        if (data.data) {
          const room = deepClone(data.data);
          this.ticket.room = Object.assign({}, this.room());
          this.workerTicketsService.changeRoom(this.siteGuidS(), this.ticket, room, () => this.roomChanged.emit(room));
          this.ticket.room = Object.assign({}, room);
        }
      });
    });
  }

  selectLittera(classification: string) {
    const clearButton = this.ticket.littera != null;
    this.modalCtrl.create({ component: SelectLitteraDialogComponent, componentProps: { clearButton, classification } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.ticket.littera = null;
          } else {
            this.ticket.littera = data.data;
          }
          this._save(this.ticket);
        }
      });
    });
  }

  saveAttachment(items: string[]) {
    this.ticket.attachments = items;
    this._save(this.ticket);
  }

  onWorkMachine(_items: WorkMachine[]) {
    // TODO
  }

  onDuration(value?: number | string) {
    if (value === null) {
      Dialog.prompt({
        title: this.translate.instant('tickets.otherTime'),
        message: this.translate.instant('tickets.otherTimeText'),
      }).then((dialogValue) => {
        if (dialogValue) {
          this.onDuration(+dialogValue.value);
        }
      });
      return;
    }
    if (value == null) {
      value = 0;
    }
    this.ticket.hours = +value;
    this._save(this.ticket);
  }

  onDeadlineChange(value: string) {
    if (value) {
      const date = startOfDay(new Date(value));
      this.ticket.deadline = date;
    } else {
      this.ticket.deadline = null;
    }
    this._save(this.ticket);
  }

  onImageUrl(imageUrl: string) {
    if (!this.ticket.images) {
      this.ticket.images = [];
    }
    this.ticket.images.push(imageUrl);
    const images: ImageItem[] = this.ticket.images.map((url) => ({
      image: url, preview$: defer(() => from(getThumb(url))).pipe(
        map((thumb) => thumb?.thumb ?? url),
        shareReplay(1),
      ),
    })) ?? [];
    this.images.set(images);
    this._save(this.ticket);
  }

  videoUrl(videoUrl: string) {
    if (!this.ticket.videos) {
      this.ticket.videos = [];
    }
    this.ticket.videos.push(videoUrl);
    const videos: CarouselImage[] = this.ticket.videos.map((url) => ({ url, type: 'video' })) ?? [];
    this.videos.set(videos);
    this._save(this.ticket);
  }

  removePaused() {
    this.ticket.status = TicketStatus.inProgress;
    this.ticket.history = this.ticket.history.filter((it) => it.status !== TicketStatus.paused);
    this._save(this.ticket);
  }

  toProgress(ask?: boolean, fromBlocked?: boolean) {
    this.takePhotos(TicketStatus.inProgress).then((ret) => {
      if (ret.role === 'cancel') {
        return;
      }
      const value = ret.data;
      if (ask) {
        Dialog.prompt({
          message: this.translate.instant('kanban.whyBackToProgress'),
        }).then((prompt) => {
          if (prompt.value) {
            this.saveTicket({ status: TicketStatus.inProgress, comment: prompt.value, images: value?.images, videos: value?.videos });
          }
        });
      } else if (fromBlocked) {
        Dialog.prompt({
          message: this.translate.instant('kanban.fromBlocked'),
        }).then((prompt) => {
          const comment = prompt.value;
          this.saveTicket({ status: TicketStatus.inProgress, comment, images: value?.images, videos: value?.videos, backFromBlocked: true });
        });
      } else {
        this.saveTicket({ status: TicketStatus.inProgress, images: value?.images, videos: value?.videos });
      }
    });
  }

  checked() {
    this.saveTicket({ status: TicketStatus.checked });
  }

  toHandled() {
    this.saveTicket({ status: TicketStatus.done, images: [], videos: [] });
  }

  toDone() {
    const user = this.usersService.currentUserS();
    if ([Roles.manager].includes(user.role)) {
      this.users$.pipe(
        take(1),
      ).subscribe((workers) => {
        if (workers?.length > 1) {
          this.selectWorker(workers);
        } else if (workers?.length === 1) {
          this.onWorker(workers[0]);
        }
        this.saveTicket({ status: TicketStatus.done });
      });
    } else {
      this.takePhotos(TicketStatus.done).then((ret) => {
        if (ret.role === 'cancel') {
          return;
        }
        const value = ret.data;
        this.saveTicket({ status: TicketStatus.done, images: value?.images, videos: value?.videos });
      });
    }
  }

  toBacklog() {
    this.saveTicket({ status: TicketStatus.created });
  }

  toBlocked(siteGuid: string, adHocTicket?: RoomTicket) {
    if (adHocTicket) {
      this.workerTicketsService.saveAdhocRoomTicket([adHocTicket], { guid: siteGuid } as Site, true);
      this.saveTicket({
        status: TicketStatus.blocked, images: adHocTicket?.images, videos: adHocTicket?.videos, blockedBy: { guid: adHocTicket.guid, name: adHocTicket.name },
      });
    } else {
      Dialog.prompt({
        message: this.translate.instant('kanban.blockDescription'),
      }).then((prompt) => {
        const comment = prompt.value;
        this.takePhotos(TicketStatus.blocked).then((ret) => {
          if (ret.role === 'cancel') {
            return;
          }
          const value = ret.data;
          this.saveTicket({ status: TicketStatus.blocked, images: value?.images, videos: value?.videos, comment });
        });
      });
    }
  }

  askToJoin() {
    if (this.ticket.requests == null) {
      this.ticket.requests = [];
    }
    const user = this.usersService.currentUserS();
    if (!this.ticket.requests.some((it) => it.guid === user.guid)) {
      this.ticket.requests.push({ guid: user.guid, name: user.displayName });
      this._save(this.ticket);
      this.asked.set(true);
    }
  }

  acceptRequest(request: { guid: string; name: string }) {
    this.ticket.requests = this.ticket.requests.filter((it) => it.guid !== request.guid);
    this.ticket.users.push(request.guid);
    this.ticket.usernames.push(request.name);
    const history = this.workerTicketsService.createHistory('joined', request.name, [], []);
    this.ticket.history.push(history);
    this._save(this.ticket);
  }

  onNote(noteInput: IonInput) {
    const text = noteInput.value as string;
    if (text != null && text.length > 0) {
      const user = this.usersService.currentUserS();
      const note: TicketNote = {
        timestamp: new Date(),
        username: user?.displayName,
        userGuid: user?.guid,
        text,
        isAdHoc: false,
      };
      if (!this.ticket.notes) {
        this.ticket.notes = [];
      }
      this.ticket.notes.push(note);
      noteInput.value = null;
      this._save(this.ticket);
    }
  }

  selectWorker(items: { guid: string; name: string; selected: boolean }[]) {
    const clearButton = this.ticket.users?.length > 0;
    this.modalCtrl.create({ component: SelectDialogComponent<User>, componentProps: { items, clearButton, multiple: true } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.ticket.users = [];
            this.ticket.usernames = [];
          } else {
            this.ticket.users = data.data.map((u) => u.guid);
            this.ticket.usernames = data.data.map((u) => u.name);
            const history = this.workerTicketsService.createHistory('assigned', data.data.map((u) => u.name)[0], [], []);
            this.ticket.history.push(history);
          }
          this._save(this.ticket);
        }
      });
    });
  }

  onWorker(item: { guid: string; name: string }) {
    this.ticket.users = [item.guid];
    this.ticket.usernames = [item.name];
    const history = this.workerTicketsService.createHistory('assigned', item.name, [], []);
    this.ticket.history.push(history);
    this._save(this.ticket);
  }

  onAdminAccept(ticket: RoomTicket) {
    const clone = Object.assign({}, ticket);
    clone.room = this.room();
    this.modalCtrl.create({ component: AcceptTicketDialogComponent, componentProps: { ticket: clone } }).then((m) => {
      m.present();
      m.onDidDismiss<RoomTicket>().then((data) => {
        if (data.data) {
          Object.assign(ticket, data.data);
          if (ticket.status === TicketStatus.blocked) {

          } else {
            this.saveTicket({ status: data.data.status });
          }
        }
      });
    });
  }

  onAdminToWork(ticket: RoomTicket) {
    ticket.additionalWork = null;
    this.saveTicket({ status: TicketStatus.created });
  }

  deleteImage(image: string) {
    this.ticket.images = this.ticket.images.filter((img) => img !== image);
    this._save(this.ticket);
  }

  onDelete() {
    if (this.ticket.repeatGuid) {
      this.modalCtrl.create({
        component: ConfirmDialogComponent, componentProps: {
          title: `${this.translate.instant('general.delete') as string} ${this.ticket.name}`,
          text: this.translate.instant('calendar.removeThisOrAll'),
          buttons: [this.translate.instant('general.cancel'), this.translate.instant('calendar.onlyThis'), this.translate.instant('tickets.all')],
        },
        cssClass: ['small-modal', 'stack-modal'],
      }).then((m) => {
        m.present();
        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data === 0) {
              return;
            } else if (data.data === 1) {
              this.delete.emit(this.ticket);
            } else if (data.data === 2) {
              // Remove all
              this.deleteAll.emit(this.ticket);
            }
          }
        });
      });
    } else {
      Dialog.confirm({
        message: `${this.translate.instant('general.delete') as string} ${this.ticket.name}?`,
        okButtonTitle: this.translate.instant('general.yes'),
        cancelButtonTitle: this.translate.instant('general.no'),
      }).then((dialog) => {
        if (dialog.value) {
          this.delete.emit(this.ticket);
        }
      });
    }
  }

  onEditName(ticket: RoomTicket) {
    Dialog.prompt({
      message: this.translate.instant('item.name'),
      inputText: ticket.name,
    }).then((dialogValue) => {
      if (dialogValue) {
        if (!dialogValue.cancelled && dialogValue.value !== ticket.name) {
          ticket.name = dialogValue.value;
          this.save.emit(ticket);
        }
      }
    });
  }

  onEditDescription(ticket: RoomTicket) {
    Dialog.prompt({
      message: this.translate.instant('calendar.site.description'),
      inputText: ticket.description ?? '',
    }).then((dialogValue) => {
      if (dialogValue) {
        if (!dialogValue.cancelled && dialogValue.value !== ticket.description) {
          ticket.description = dialogValue.value;
          this.save.emit(ticket);
        }
      }
    });
  }

  onCopy(ticket: RoomTicket) {
    this.workerTicketsService.openSiteCalendarDialog({ site: ticket.site, ticket, room: this.room() }).then((value) => {
      if (value) {
        this.dismiss.emit();
      }
    });
  }
}
