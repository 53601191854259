import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Dialog } from '@capacitor/dialog';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SelectClientDialogComponent } from '../clients/select-client/select-client.dialog';
import { SelectSiteDialog } from '../components/select-site/select-site.dialog';
import { FreeLicenseDialogComponent } from '../contractors/free-license/free-license.dialog';
import { InstructionPopover } from '../instructions/new/popover/instruction.popover';
import { NewMaterialDialog } from '../materials/new-material/new-material.dialog';
import { Client } from '../models/client.model';
import { ListItem } from '../models/list-item.model';
import { News } from '../models/news.model';
import { PartnerNote } from '../models/partner-note.model';
import { Room } from '../models/room.model';
import { SiteArea } from '../models/site-area.model';
import { SiteProjectPeriod } from '../models/site-project-period.model';
import { SiteProject } from '../models/site-project.model';
import { Site } from '../models/site.model';
import { User } from '../models/user.model';
import { NewNewsDialogComponent } from '../news/dialog/new-news.dialog';
import { PartnerNoteDialogComponent } from '../notes/partner/dialog/partner-note.dialog';
import { NewSiteReportDialogComponent } from '../sites/site/components/reports/new/new-site-report.dialog';
import { SiteReviewDialogComponent } from '../sites/site/components/reviews/dialog/review.dialog';
import { AddAreaDialogComponent } from '../sites/site/components/rooms/components/add-area/add-area.dialog';
import { NewTimesheetDialogComponent } from '../timesheets/new-timesheet/new-timesheet.dialog';
import { InviteUserDialogComponent } from '../users/invite/invite-user.dialog';
import { Roles } from '../utility/role';
import { WorkMachineDialogComponent } from '../work-machines/dialog/work-machine.dialog';
import { NewMessageDialogComponent } from '../worker/messages/new-message/dialogs/new-message.dialog';
import { ContractorsService } from './contractors.service';
import { InitializeService } from './initialize.service';
import { MaterialsService } from './materials.service';
import { NewsService } from './news.service';
import { PartnerNotesService } from './partner-notes.service';
import { SiteAreasService } from './site-areas.service';
import { SiteProjectsService } from './site-projects.service';
import { TimesheetsService } from './timesheets.service';
import { UpdateService } from './update.service';
import { UsersService } from './users.service';
import { WorkMachinesService } from './work-machines.service';
import { WorkerTicketsService } from './worker-tickets.service';

@Injectable({
  providedIn: 'root',
})
export class PlusService {

  private contractorsService = inject(ContractorsService);
  private initializeService = inject(InitializeService);
  private materialsService = inject(MaterialsService);
  private modalCtrl = inject(ModalController);
  private newsService = inject(NewsService);
  private partnerNotesService = inject(PartnerNotesService);
  private popoverController = inject(PopoverController);
  private router = inject(Router);
  private siteAreasService = inject(SiteAreasService);
  private siteProjectsService = inject(SiteProjectsService);
  private timesheetsService = inject(TimesheetsService);
  private translate = inject(TranslateService);
  private updateService = inject(UpdateService);
  private usersService = inject(UsersService);
  private workMachinesService = inject(WorkMachinesService);
  private workerTicketsService = inject(WorkerTicketsService);

  isLoading = signal(false);

  inviteUser() {
    this.modalCtrl.create({ component: InviteUserDialogComponent }).then((m) => {
      m.present();

      m.onDidDismiss<User>().then((data) => {
        if (data.data) {
          this.isLoading.set(true);
          this.usersService.invite(data.data).pipe(
            take(1),
          ).subscribe((value) => {
            this.isLoading.set(false);
            if (value.result) {
              this.initializeService.updateUsers().pipe(
                take(1),
              ).subscribe((updated) => {
                if (updated) {
                  this.router.navigate(['/users', 'user', value.user]);
                }
              });
            } else {
              Dialog.alert({
                title: this.translate.instant('users.creation'),
                message: this.translate.instant('users.creationFailed'),
              });
            }
          });
        }
      });
    });
  }

  openLicense() {
    this.modalCtrl.create({ component: FreeLicenseDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
  }

  async onAdHoc(sites: Site[], project?: SiteProject, job?: SiteProjectPeriod, setWorker = false, skipToast = false) {
    if (sites.length > 1 || sites.length === 0) {
      return this.workerTicketsService.openSiteCalendarDialog({ site: null, project, job, sites, setWorker, skipToast });
    } else if (sites.length === 1) {
      const site = sites[0];
      return this.workerTicketsService.openSiteCalendarDialog({ site, project, job, sites, setWorker, skipToast });
    }
  }

  onNewReport(site: Site) {
    this.modalCtrl.create({ component: NewSiteReportDialogComponent, componentProps: { site } }).then((m) => {
      m.present();
    });
  }

  onNewReview(site: Site) {
    this.modalCtrl.create({ component: SiteReviewDialogComponent, componentProps: { site } }).then((m) => {
      m.present();
    });
  }

  onInstruction(event: Event) {
    this.popoverController.create({ component: InstructionPopover, event }).then((p) => {
      p.present();
    });
  }

  onNewClient() {
    this.modalCtrl
      .create({ component: SelectClientDialogComponent })
      .then((m) => {
        m.present();
        m.onDidDismiss<Client>().then((data) => {
          if (data.data) {
            const contractor = this.contractorsService.contractorS();
            if (contractor) {
              if (contractor.clients == null) {
                contractor.clients = [];
              }
              contractor.clients.push(data.data.guid);
              this.contractorsService.update(contractor).then(() => {
                this.initializeService.updateClients().pipe(
                  take(1),
                ).subscribe();
              });
            }
          }
        });
      });
  }

  onNewMessage() {
    this.router.navigate(['/worker', 'messages', 'new']);
  }

  onNews(segment: string) {
    if (segment === 'feedbacks') {
      this.modalCtrl.create({ component: NewMessageDialogComponent }).then((m) => {
        m.present();
      });
    } else {
      this.modalCtrl.create({ component: NewNewsDialogComponent }).then((m) => {
        m.present();

        m.onDidDismiss<News>().then((data) => {
          if (data.data) {
            this.newsService.save(data.data);
          }
        });
      });
    }
  }

  async onNewSite(clientGuid: string, allSites: Site[]) {
    const sites = allSites.filter((site) => site.client === clientGuid);
    return this.modalCtrl
      .create({ component: SelectSiteDialog, componentProps: { sites, clientGuid, showAdd: true } })
      .then(async (m) => {
        m.present();
        return m.onDidDismiss<Site>().then((data) => {
          if (data.data) {
            const contractor = this.contractorsService.contractorS();
            if (contractor) {
              if (contractor.sites == null) {
                contractor.sites = [];
              }
              contractor.sites.push(data.data.guid);
              if (data.data.client && !contractor.clients.includes(data.data.client)) {
                contractor.clients.push(data.data.client);
              }
              this.contractorsService.update(contractor).then(() => {
                // Add default project to site
                this.siteProjectsService.createDefaultProject(data.data, contractor.guid);
                this.initializeService.update();
              });
            } else {
              const user = this.usersService.currentUserS();
              if (user.role === Roles.manager) {
                user.worksites.push(data.data.guid);
                this.usersService.updateWorksites(user.guid, user.worksites);
              }
            }
            return data.data;
          }
        });
      });
  }

  onNewTimesheet(site?: Site, user?: User) {
    this.modalCtrl
      .create({ component: NewTimesheetDialogComponent, componentProps: { site, user } })
      .then((m) => {
        m.present();
        m.onDidDismiss().then((data) => {
          if (data.data) {
            const timesheet = data.data;
            this.timesheetsService.save(timesheet, true).then(() => this.updateService.update());
          }
        });
      });
  }

  onNewPartnerNote() {
    this.modalCtrl
      .create({ component: PartnerNoteDialogComponent })
      .then((m) => {
        m.present();
        m.onDidDismiss<PartnerNote>().then((data) => {
          if (data.data) {
            data.data.inviter = this.usersService.currentUserS().guid;
            this.partnerNotesService.save(data.data);
          }
        });
      });
  }

  onOrder() {
    this.router.navigate(['/materials/order']);
  }

  newProduct() {
    this.modalCtrl.create({ component: NewMaterialDialog }).then((m) => {
      m.present();

      m.onDidDismiss<ListItem>().then((data) => {
        if (data.data) {
          this.materialsService.save(data.data);
        }
      });
    });
  }

  onWorkMachine() {
    this.modalCtrl.create({ component: WorkMachineDialogComponent }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.workMachinesService.save(data.data);
        }
      });
    });
  }

  private deleteChild(item: SiteArea, id: string) {
    if (item.children.length === 0) {
      return item.id !== id ? item : null;
    }

    item.children = item.children.map((c) => this.deleteChild(c, id)).filter((c) => c != null);
    return item;
  }

  onAddAreas(site: Site, parent?: SiteArea, root?: SiteArea) {
    this.modalCtrl.create({
      component: AddAreaDialogComponent,
      componentProps: { parent, root },
      backdropDismiss: false,
      cssClass: ['stack-modal'],
    }).then((m) => {
      m.present();

      m.onDidDismiss<{
        areas?: string[]; rooms?: Room[]; delete?: boolean; name?: string;
      }>().then((data) => {
        if (data.data) {
          if (data.data.rooms) {
            parent.rooms = data.data.rooms;
            this.siteAreasService.update(root);
            return;
          }
          if (data.data.delete) {
            if (root.id === parent.id) {
              this.siteAreasService.delete(root);
            } else {
              root.children = root.children.map((child) => this.deleteChild(child, parent.id));
              this.siteAreasService.update(root);
            }
            return;
          }
          if (data.data.areas) {
            const existing = parent?.children?.map((it) => it) ?? [];
            if (parent) {
              parent.children = [];
            }
            if (data.data.areas.length === 0) {
              if (parent?.guid) {
                parent.name = data.data.name;
                this.siteAreasService.update(parent);
              } else {
                this.siteAreasService.update(root);
              }
            } else {
              data.data.areas.forEach((areaName) => {
                const id = parent ? `${parent.id}${parent.children.length}` : '0';
                const area: SiteArea = {
                  id,
                  name: areaName,
                  site: site.guid,
                  rooms: existing?.find((it) => it.id === id)?.rooms ?? [],
                  children: existing?.find((it) => it.id === id)?.children ?? [],
                };
                if (parent) {
                  parent.children.push(area);
                  if (parent.guid) {
                    parent.name = data.data.name;
                    this.siteAreasService.update(parent);
                  } else {
                    this.siteAreasService.update(root);
                  }
                } else {
                  this.siteAreasService.save(area);
                }
              });
            }
          }
        }
      });
    });
  }
}
